import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as organizationsApi from '@copper/api/organizations';
import { fetchApprovalRequests } from '@copper/entities/approvalRequests/approvalRequests-reducer';
import { setUnpaidInvoices } from '@copper/entities/invoices/invoices-reducer';
import { normalizeOrganization } from './organization-normalize';
const initialState = {
    currentOrganization: {},
    organizations: [],
    organizationFees: {},
    organizationInfo: {},
    organizationRestrictedResources: []
};
export const fetchOrganization = createAsyncThunk('organizations/get', async ({ organizationId }, thunkAPI) => {
    try {
        const response = await organizationsApi.get({ organizationId });
        thunkAPI.dispatch(setUnpaidInvoices(response.data._embedded?.unpaidInvoices ?? []));
        return normalizeOrganization(response.data);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchOrganizations = createAsyncThunk('organizations/active', async (_, thunkAPI) => {
    try {
        const response = await organizationsApi.getByQuery({ isActive: true });
        return response.data.organizations.map((rawOrganization) => normalizeOrganization(rawOrganization));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchOrganizationFees = createAsyncThunk('organization/fees', async (organizationId, thunkAPI) => {
    try {
        const response = await organizationsApi.getFees(organizationId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchOrganizationInfo = createAsyncThunk('organization/info', async (organizationId, thunkAPI) => {
    try {
        const response = await organizationsApi.getOrganizationInfo(organizationId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchOrganizationRestrictedResources = createAsyncThunk('organization/restrictedResources', async (organizationId, thunkAPI) => {
    try {
        const response = await organizationsApi.getRestrictedResources(organizationId);
        return response.data.restrictedResources;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const addMember = createAsyncThunk('organization/addMember', async ({ userId, organizationId, memberData, twoFaCode }, thunkAPI) => {
    try {
        const { data } = await organizationsApi.addMember(organizationId, memberData, twoFaCode);
        if (data?.userId) {
            thunkAPI.dispatch(fetchOrganisationMemberDetails({ organizationId, userId: data.userId }));
        }
        else {
            thunkAPI.dispatch(fetchApprovalRequests({ userId, organizationId }));
        }
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateMemberPermissionSet = createAsyncThunk('organization/updateMemberPermissionSet', async ({ userId, organizationId, permissions, portfolioIds, twoFaCode }, thunkAPI) => {
    try {
        const res = await organizationsApi.updateMemberPermissionSet({
            organizationId,
            userId,
            permissions,
            portfolioIds,
            twoFaCode
        });
        thunkAPI.dispatch(fetchOrganisationMemberDetails({
            organizationId,
            userId,
            includeActiveApprovalRequests: true
        }));
        // returning the status code to handle the response in the component and show the appropriate message according to approval flow (202 approval request created, 200 changes applied)
        return res.status;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const addMemberPermissionSet = createAsyncThunk('organization/addMemberPermissionSet', async ({ userId, organizationId, permissions, portfolioIds, twoFaCode }, thunkAPI) => {
    try {
        const res = await organizationsApi.addMemberPermissionSet({
            organizationId,
            userId,
            permissions,
            portfolioIds,
            twoFaCode
        });
        thunkAPI.dispatch(fetchOrganisationMemberDetails({
            organizationId,
            userId,
            includeActiveApprovalRequests: true
        }));
        // returning the status code to handle the response in the component and show the appropriate message according to approval flow (202 approval request created, 200 changes applied)
        return res.status;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateMemberOrderLimits = createAsyncThunk('organization/updateMemberOrderLimits', async ({ userId, organizationId, duration, byOrderAmounts, byTotalWithdrawals, portfolioIds, twoFaCode }, thunkAPI) => {
    try {
        const res = await organizationsApi.updateMemberOrderLimits({
            organizationId,
            userId,
            duration,
            byOrderAmounts,
            byTotalWithdrawals,
            portfolioIds,
            twoFaCode
        });
        thunkAPI.dispatch(fetchOrganisationMemberDetails({
            organizationId,
            userId,
            includeActiveApprovalRequests: true
        }));
        // returning the status code to handle the response in the component and show the appropriate message according to approval flow (202 approval request created, 200 changes applied)
        return res.status;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const addMemberOrderLimits = createAsyncThunk('organization/addMemberOrderLimits', async ({ userId, organizationId, duration, byOrderAmounts, byTotalWithdrawals, portfolioIds, twoFaCode }, thunkAPI) => {
    try {
        const res = await organizationsApi.addMemberOrderLimits({
            organizationId,
            userId,
            duration,
            byOrderAmounts,
            byTotalWithdrawals,
            portfolioIds,
            twoFaCode
        });
        thunkAPI.dispatch(fetchOrganisationMemberDetails({
            organizationId,
            userId,
            includeActiveApprovalRequests: true
        }));
        // returning the status code to handle the response in the component and show the appropriate message according to approval flow (202 approval request created, 200 changes applied)
        return res.status;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteMemberPermissionSet = createAsyncThunk('organization/deleteMemberPermissionSet', async ({ userId, organizationId, permissionSet, twoFaCode }, thunkAPI) => {
    try {
        await organizationsApi.deleteMemberPermissionSet({
            organizationId,
            userId,
            permissionSet,
            twoFaCode
        });
        thunkAPI.dispatch(fetchOrganisationMemberDetails({
            organizationId,
            userId,
            includeActiveApprovalRequests: true
        }));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteMemberOrderLimitSet = createAsyncThunk('organization/deleteMemberOrderLimitSet', async ({ userId, organizationId, orderLimitSet, twoFaCode }, thunkAPI) => {
    try {
        await organizationsApi.deleteMemberOrderLimitSet({
            organizationId,
            userId,
            orderLimitSet,
            twoFaCode
        });
        thunkAPI.dispatch(fetchOrganisationMemberDetails({
            organizationId,
            userId,
            includeActiveApprovalRequests: true
        }));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteOrganizationMember = createAsyncThunk('organization/delete-member', async (payload, thunkAPI) => {
    try {
        await organizationsApi.deleteOrganizationMember(payload);
        return payload.userId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchOrganisationMemberDetails = createAsyncThunk('organization/fetch-member-details', async (payload, thunkAPI) => {
    try {
        const { data: { userId, _embedded } } = await organizationsApi.getMemberDetails(payload);
        const { ipWhitelist = [], permissionSets, orderLimits, approvalRequests } = _embedded ?? {};
        return { ipWhitelist, permissionSets, orderLimits, userId, approvalRequests };
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateBankAccount = createAsyncThunk('organization/updateBankAccount', async ({ twoFaCode, data, bankAccountId }, thunkAPI) => {
    try {
        const response = await organizationsApi.updateBankAccount(data, bankAccountId, twoFaCode);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteBankAccount = createAsyncThunk('organization/deleteBankAccount', async ({ twoFaCode, bankAccountId }, thunkAPI) => {
    try {
        const response = await organizationsApi.deleteBankAccount(bankAccountId, twoFaCode);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateOrganizationBlocked = createAsyncThunk('organization/updateBlocked', async ({ organizationId, data, twoFaCode }, thunkAPI) => {
    try {
        await organizationsApi.updateBlocked(organizationId, data, twoFaCode);
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateOrganizationFees = createAsyncThunk('organization/updateFees', async ({ organizationId, data }, thunkAPI) => {
    try {
        const response = await organizationsApi.updateFees(organizationId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateOrganizationInfo = createAsyncThunk('organization/updateInfo', async ({ organizationId, data }, thunkAPI) => {
    try {
        const response = await organizationsApi.updateInfo(organizationId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateOrganizationMembersExemptions = createAsyncThunk('organization/updateMembersExemptions', async ({ organizationId, data, twoFaCode }, thunkAPI) => {
    try {
        await organizationsApi.updateMembersExemptions(organizationId, data, twoFaCode);
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateOrganizationSettings = createAsyncThunk('organization/updateSettings', async ({ organizationId, data, twoFaCode }, thunkAPI) => {
    try {
        const response = await organizationsApi.updateSettings(organizationId, data, twoFaCode);
        return normalizeOrganization(response.data);
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const archiveOrganization = createAsyncThunk('organization/archive', async (organizationId, thunkApi) => {
    try {
        await organizationsApi.archiveOrganization(organizationId);
        return { organizationId };
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const unarchiveOrganization = createAsyncThunk('organization/unarchive', async (organizationId, thunkApi) => {
    try {
        await organizationsApi.unarchiveOrganization(organizationId);
        return { organizationId };
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const updateLeverageRatioThreshold = createAsyncThunk('organization/updateLeverageRatioThreshold', async ({ organizationId, data, twoFaCode }, thunkApi) => {
    try {
        const response = await organizationsApi.updateLeverageRatioThreshold(organizationId, data, twoFaCode);
        return normalizeOrganization(response.data);
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const updateCollateralCurrencies = createAsyncThunk('organization/updateCollateralCurrencies', async ({ organizationId, data, twoFaCode }, thunkApi) => {
    try {
        const response = await organizationsApi.updateCollateralCurrencies(organizationId, data, twoFaCode);
        return normalizeOrganization(response.data);
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const updateAlertThresholdDeltas = createAsyncThunk('organization/updateAlertThresholdDeltas', async ({ organizationId, data, twoFaCode }, thunkApi) => {
    try {
        const response = await organizationsApi.updateAlertThresholdDeltas(organizationId, data, twoFaCode);
        return normalizeOrganization(response.data);
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const updateAutoDefaultLendingSettings = createAsyncThunk('organization/updateAlertThresholdDeltas', async ({ organizationId, data, twoFaCode }, thunkApi) => {
    try {
        const response = await organizationsApi.updateAutoDefaultLendingSettings(organizationId, data, twoFaCode);
        return normalizeOrganization(response.data);
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const resetMasterPassword = createAsyncThunk('organization/resetMasterPassword', async ({ organizationId, twoFaCode }, thunkApi) => {
    try {
        await organizationsApi.resetMasterPassword(organizationId, twoFaCode);
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        setOrganizationCounterparty(state, { payload }) {
            state.currentOrganization.counterparty = payload;
        },
        updateOrganizationAuthFlag(state, { payload }) {
            state.organizations = state.organizations.map((organization) => organization.organizationId === payload
                ? { ...organization, isAuthenticated: true }
                : organization);
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
            state.organizations = payload;
        });
        addCase(fetchOrganization.fulfilled, (state, { payload }) => {
            state.currentOrganization = payload;
        });
        addCase(fetchOrganisationMemberDetails.fulfilled, (state, { payload }) => {
            state.currentOrganization.members[payload.userId] = {
                ...state.currentOrganization.members[payload.userId],
                ...payload
            };
        });
        addCase(deleteOrganizationMember.fulfilled, (state, { payload: userId }) => {
            state.currentOrganization.members[userId] = {
                ...state.currentOrganization.members[userId],
                isActive: false,
                isDeleted: true
            };
        });
        addCase(updateOrganizationMembersExemptions.fulfilled, (state, { payload }) => {
            const updatedOrganizationMembers = payload.exemptionUpdates.reduce((acc, user) => ({
                ...acc,
                [user.userId]: {
                    ...state.currentOrganization.members[user.userId],
                    isExemption: user.exempt
                }
            }), {});
            state.currentOrganization.members = {
                ...state.currentOrganization.members,
                ...updatedOrganizationMembers
            };
        });
        addCase(fetchOrganizationFees.fulfilled, (state, { payload }) => {
            state.organizationFees = payload;
        });
        addCase(fetchOrganizationInfo.fulfilled, (state, { payload }) => {
            state.organizationInfo = payload;
        });
        addCase(fetchOrganizationRestrictedResources.fulfilled, (state, { payload }) => {
            state.organizationRestrictedResources = payload;
        });
        addCase(updateBankAccount.fulfilled, (state, { payload }) => {
            state.currentOrganization.bankAccounts = state.currentOrganization.bankAccounts?.reduce((acc, bankAccount) => {
                if (bankAccount.bankAccountId === payload.bankAccountId) {
                    return acc.concat(payload);
                }
                return acc.concat(bankAccount);
            }, [payload]);
        });
        addCase(updateOrganizationBlocked.fulfilled, (state, { payload }) => {
            state.currentOrganization.extra = {
                ...state.currentOrganization.extra,
                blocked: payload.blocked
            };
        });
        addCase(updateOrganizationFees.fulfilled, (state, { payload }) => {
            state.organizationFees = payload;
        });
        addCase(updateOrganizationInfo.fulfilled, (state, { payload }) => {
            state.organizationInfo = payload;
        });
        addCase(updateOrganizationSettings.fulfilled, (state, { payload }) => {
            state.currentOrganization = payload;
        });
        addCase(archiveOrganization.fulfilled, (state, { payload }) => {
            if (state.currentOrganization.organizationId === payload.organizationId) {
                state.currentOrganization.isActive = false;
            }
            state.organizations = state.organizations.map((organization) => organization.organizationId === payload.organizationId
                ? { ...organization, isActive: false }
                : organization);
        });
        addCase(unarchiveOrganization.fulfilled, (state, { payload }) => {
            if (state.currentOrganization.organizationId === payload.organizationId) {
                state.currentOrganization.isActive = true;
            }
            state.organizations = state.organizations.map((organization) => organization.organizationId === payload.organizationId
                ? { ...organization, isActive: true }
                : organization);
        });
        addCase(updateLeverageRatioThreshold.fulfilled, (state, { payload }) => {
            state.currentOrganization = payload;
        });
        addCase(updateCollateralCurrencies.fulfilled, (state, { payload }) => {
            state.currentOrganization = payload;
        });
        addCase(updateAlertThresholdDeltas.fulfilled, (state, { payload }) => {
            state.currentOrganization = payload;
        });
        addCase(resetMasterPassword.fulfilled, (state) => {
            delete state.currentOrganization.extra?.encryptionPublicKey;
        });
    }
});
const { reducer, actions } = organizationsSlice;
export const { setOrganizationCounterparty, updateOrganizationAuthFlag } = actions;
export const organizations = reducer;
