import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { ResultSignInfo } from '@copper/components/Forms/ResultSignInfo';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName } from '@copper/helpers';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Alert } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import s from './Result.module.css';
const Result = ({ transaction, errorMessage, formData, onDone }) => {
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const { getDecimals } = useCurrenciesInfo();
    const getResultStatus = () => {
        if (errorMessage) {
            return 'failure';
        }
        if (transaction?.status === 'co-sign-require') {
            return 'signature';
        }
        if (transaction?.status === 'processing' ||
            (transaction?.status !== 'executed' && transaction?.status !== 'new')) {
            return 'pending';
        }
        return 'success';
    };
    const status = getResultStatus();
    const { origin, destination } = formData;
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: errorMessage
                    ? 'Transfer failed'
                    : `Awaiting approval ${transaction?.status === 'co-sign-require' ? 'by Client' : 'by Copper'}` }), _jsxs(FormResultAccent, { children: [formatCurrency(formData.amount ?? 0, {
                        decimalPlaces: getDecimals(formData.currency?.currency, formData.mainCurrency, {
                            useDictionaryDecimal: true
                        })
                    }), ' ', getCurrencyName(formData.currency?.currency ?? '', currenciesEntities)] }), _jsx(FormResultRegular, { children: _jsxs(_Fragment, { children: ["From ", _jsx(NavLink, { to: `/accounts/${origin?.portfolioId}`, children: origin?.portfolioName }), " to your \"", destination?.bankAccountName ?? 'Unknown', "\" bank account"] }) }), errorMessage ? (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) })) : (_jsx(ResultSignInfo, { currencyId: formData.currency?.currency, transaction: transaction, origin: formData.origin }))] }));
};
export { Result };
