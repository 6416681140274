import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { AssetIcon } from '@copper/components';
import { getAvailableBalance } from '@copper/components/Forms/Amount/helpers';
import { reportingCurrency } from '@copper/constants/currency';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { IconWallet } from '@copper/ui-kit';
import { FundsInput } from '@copper/ui-kit/FundsInput';
import { formatFiat, formatShortCurrency } from '@copper/utils';
import { selectNetworks } from '@copper/entities/networks/networks-selectors';
import s from './FundsAmount.module.css';
export const FundsAmount = ({ inputName, selectName, portfolioId, control, currencyItems, excludeCurrencyIds, readonlyCurrency = false, rightBottomSection, view = 'primary', mainCurrencyId, onInputValueChange, minDecimalPoints = 0, getBottomRow = (asset) => asset?.currency, disabled = false }) => {
    const { getDecimals } = useCurrenciesInfo();
    const currenciesRates = useSelector(selectRates);
    const portfolios = useSelector(selectPortfolios);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const networks = useSelector(selectNetworks);
    const { field: { value: inputValue, onChange: onInputChange } } = useController({
        name: inputName,
        control
    });
    const { field: { value: selectedAsset, onChange: onSelectChange } } = useController({
        name: selectName,
        control
    });
    const decimal = Math.max(getDecimals(selectedAsset?.currency, networks[mainCurrencyId ?? '']?.nativeCurrency, {
        useDictionaryDecimal: true
    }), minDecimalPoints);
    const currenciesOptionList = useMemo(() => {
        if (excludeCurrencyIds) {
            return currencyItems.filter(({ currency }) => !excludeCurrencyIds.includes(currency));
        }
        return currencyItems;
    }, [excludeCurrencyIds, currencyItems]);
    const walletAvailableBalance = useMemo(() => {
        const portfolio = portfolios[portfolioId ?? ''];
        return getAvailableBalance({
            walletsByPortfolioId,
            currenciesEntities,
            portfolio,
            currencyId: selectedAsset?.currency,
            mainCurrencyId: mainCurrencyId
        });
    }, [
        portfolios,
        portfolioId,
        walletsByPortfolioId,
        currenciesEntities,
        selectedAsset,
        mainCurrencyId
    ]);
    const amountMarketValue = useMemo(() => {
        const rate = selectedAsset?.currency === reportingCurrency
            ? '1'
            : currenciesRates[selectedAsset?.currency ?? ''];
        return new BigNumber(inputValue).times(rate ?? 0).toFixed();
    }, [currenciesRates, inputValue, selectedAsset]);
    const renderWalletAvailableBalance = useCallback(() => {
        if (!walletAvailableBalance) {
            return null;
        }
        return (_jsxs("button", { type: "button", className: s.assetAvailable, onClick: () => onInputChange(new BigNumber(walletAvailableBalance).isGreaterThan('0')
                ? walletAvailableBalance
                : '0.00'), children: [_jsx(IconWallet, { width: "16px", height: "16px" }), formatShortCurrency(walletAvailableBalance)] }));
    }, [walletAvailableBalance]);
    return (_jsx(FundsInput, { view: view, inputName: inputName, inputDecimal: decimal, inputValue: inputValue ?? '', onInputChange: (value, sourceInfo) => {
            onInputChange(value);
            onInputValueChange?.(value, sourceInfo);
        }, selectName: selectName, selectItems: currenciesOptionList, selectValue: selectedAsset, onSelectChange: onSelectChange, getSelectKey: (asset) => asset.currency, getSelectIcon: (asset) => _jsx(AssetIcon, { currency: asset.currency, size: "s" }), getSelectBottomRow: getBottomRow, getSelectTopRow: (asset, isSelected) => (isSelected ? undefined : asset.name), tooltipLabel: selectedAsset ? `Name: ${selectedAsset?.name}` : undefined, readonlyCurrency: readonlyCurrency, leftBottomSection: !BigNumber(inputValue).isNaN() ? formatFiat(amountMarketValue) : undefined, rightBottomSection: rightBottomSection ? rightBottomSection : renderWalletAvailableBalance(), disabled: disabled }));
};
