import * as Yup from 'yup';
export const mmfStableCoin = 'USDC';
export const mainCurrency = 'ETH';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.'),
    baseAmount: Yup.string().required('Enter the amount.'),
    baseCurrency: Yup.object().required('Select a currency.'),
    quoteCurrency: Yup.object().required('Select a currency.'),
    quoteAmount: Yup.string().required('Enter the amount.')
});
