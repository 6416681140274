import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Amount, AssetIcon, Network } from '@copper/components';
import { TransferPortfolio } from '@copper/components/Forms/TransferPortfolio';
import { selectActivePlatformCurrencies } from '@copper/entities/currencies/currencies-selector';
import { checkRequiredNetwork } from '@copper/helpers/validation';
import { FormControl, FormLabel, Radio, Select, Switch } from '@copper/ui-kit';
import { getCurrencyCode } from './helpers';
import s from './Rules.module.css';
export const BalanceOnAccountForm = ({ control, errors, watch }) => {
    const activeCurrencies = useSelector(selectActivePlatformCurrencies);
    const cryptoActiveCurrencies = useMemo(() => activeCurrencies.filter((currency) => !currency?.fiat), [activeCurrencies]);
    const position = [
        {
            label: 'Is below',
            value: 'below'
        },
        {
            label: 'Is above',
            value: 'above'
        }
    ];
    const [currency, amountBy, portfolio, transferPortfolio, ruleType] = watch([
        'currency',
        'amountBy',
        'portfolio',
        'transferPortfolio',
        'ruleType'
    ]);
    const currencyCode = getCurrencyCode(currency, amountBy);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.heading, children: _jsxs("div", { children: [_jsx("h5", { className: s.headingTitle, children: "Rules" }), _jsx("p", { className: s.headingSubTitle, children: "Create transfer rules to automatically rebalance your accounts." })] }) }), _jsx("div", { className: s.divider }), _jsx("h6", { className: s.formTitle, children: "If the balance for" }), _jsxs("div", { children: [_jsx(TransferPortfolio, { type: "origin", view: "tool", errorMessage: errors.portfolio?.message, name: "portfolio", label: "account", control: control, defaultShowUnavailable: true, testId: "rule-portfolio" }), _jsx(FormControl, { children: _jsx(Controller, { name: "ruleType", control: control, render: ({ field }) => (_jsx(Radio, { items: position, value: field.value, onChange: field.onChange, name: "ruleType" })) }) }), _jsxs(FormControl, { errorMessage: errors.currency?.message, children: [_jsx(FormLabel, { view: "tool", children: "Asset" }), _jsx(Controller, { control: control, name: "currency", render: ({ field }) => (_jsx(Select, { view: "tool", placeholder: "Select asset", searchPlaceholder: "Search assets", items: cryptoActiveCurrencies, searchFields: ['name', 'currency'], getKey: (asset) => asset?.currency, getIcon: (asset) => _jsx(AssetIcon, { currency: asset?.currency, size: "s" }), getTopRow: (asset) => asset?.name, getBottomRow: (asset) => asset?.currency, onChange: field.onChange, selected: field.value, testId: "rule-asset" })) })] }), currency && (_jsx(Network, { view: "tool", control: control, name: "mainCurrency", currency: currency?.currency, required: checkRequiredNetwork({
                            origin: transferPortfolio,
                            destination: portfolio
                        }), errorMessage: errors.mainCurrency?.message, testId: "rule-network" })), _jsx(Amount, { name: "amountThreshold", view: "tool", control: control, errorMessage: errors.amountThreshold?.message, hideSlider: true, testId: "rule-amount-threshold", rightSection: currencyCode, leftSection: currencyCode && _jsx(AssetIcon, { size: "s", currency: currencyCode }) }), currency && (_jsx("div", { className: s.switchContainer, children: _jsx(Controller, { control: control, name: "amountBy", defaultValue: "base", render: ({ field }) => (_jsxs("button", { type: "button", className: s.switchButton, onClick: () => field.onChange(field.value === 'base' ? 'quote' : 'base'), "data-testid": "usd-amount-switch", children: ["Specify amount in ", amountBy === 'quote' ? currency?.currency : 'USD'] })) }) })), _jsx(FormControl, { children: _jsxs("div", { className: s.switchRow, children: [_jsx("label", { className: s.switchLabel, htmlFor: "balanceType", children: "Use available balance" }), ' ', _jsx(Controller, { control: control, name: "balanceType", render: ({ field }) => (_jsx(Switch, { checked: field.value === 'available', onChange: (checked) => field.onChange(checked ? 'total' : 'available') })) })] }) })] }), _jsxs("h6", { className: s.formTitle, children: ["Transfer assets ", ruleType === 'above' ? 'to' : 'from'] }), _jsxs("div", { children: [_jsx(TransferPortfolio, { view: "tool", type: "origin", errorMessage: errors.transferPortfolio?.message, name: "transferPortfolio", label: "account", control: control, defaultShowUnavailable: true, testId: "rule-transfer-portfolio" }), ruleType === 'below' && (_jsx(Amount, { name: "transferMinBalance", formLabel: "Leaving a min balance of", view: "tool", control: control, rightSection: currencyCode, hideSlider: true, errorMessage: errors.transferMinBalance?.message, testId: "rule-transfer-min-balance", leftSection: currencyCode && _jsx(AssetIcon, { size: "s", currency: currencyCode }) }))] })] }));
};
