import { createSelector } from '@reduxjs/toolkit';
import { omit, pick } from 'lodash-es';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectCurrentOrganization, selectCustodyRestrictedResources } from '@copper/entities/organizations/organizations-selector';
import { selectOxygenWallets, selectWalletList } from '@copper/entities/wallets/wallets-selector';
import { isOxygenFiat, sortCurrencies } from '@copper/helpers';
export const selectCurrenciesEntities = (store) => store.dictionaries?.currencies?.entities;
export const selectTokenEntities = (store) => store.dictionaries.currencies.tokens;
export const selectTokens = createSelector(selectTokenEntities, (tokenEntities) => Object.values(tokenEntities));
export const selectCurrencyList = createSelector(selectCurrencies, ({ entities: currenciesEntities }) => sortCurrencies(Object.values(currenciesEntities)));
export const selectAccessibleCurrencies = createSelector(selectCurrencies, selectCustodyRestrictedResources, ({ entities: currenciesEntities, tokens }, custodyRestrictedResources) => {
    const accessibleCurrencies = getAccessibleCurrencies(currenciesEntities, custodyRestrictedResources);
    // The currency is not active if there are no tags
    const activeCurrencies = Object.values(accessibleCurrencies).filter((currency) => currency.mainCurrencies.some((mainCurrency) => Boolean(tokens[`${currency.currency}-${mainCurrency}`]?.tags?.length)));
    return sortCurrencies(activeCurrencies);
});
// currencies available in Transfer, Settlement, etc - usual platform forms
export const selectActivePlatformCurrencies = createSelector(selectWalletList, selectCurrencies, (wallets, { entities: currenciesEntities }) => {
    const currencies = wallets.reduce((acc, wallet) => {
        if (!acc[wallet.currency] &&
            currenciesEntities[wallet.currency] &&
            wallet.portfolioType !== 'oxygen-vault') {
            acc[wallet.currency] = currenciesEntities[wallet.currency];
        }
        return acc;
    }, {});
    return sortCurrencies(Object.values(currencies));
});
// Oxygen specific currencies
export const selectActiveOxygenCurrencies = createSelector(selectOxygenWallets, selectCurrencies, (wallets, { entities: currenciesEntities }) => {
    const currencies = wallets.reduce((acc, wallet) => {
        if (!acc[wallet.currency] && currenciesEntities[wallet.currency]) {
            acc[wallet.currency] = currenciesEntities[wallet.currency];
        }
        return acc;
    }, {});
    return sortCurrencies(Object.values(currencies));
});
export const selectOxygenCollateralCurrencies = createSelector(selectActiveOxygenCurrencies, selectTokenEntities, (currencies, tokens) => currencies.filter((currency) => {
    const token = tokens[`${currency.currency}-${currency.mainCurrencies[0]}`];
    return token?.tags?.includes('oxygen-collateral');
}));
const getAccessibleCurrencies = (currenciesEntities, custodyRestrictedResources) => {
    if (!custodyRestrictedResources?.resources)
        return currenciesEntities;
    if (custodyRestrictedResources.operator === 'contains') {
        return pick(currenciesEntities, custodyRestrictedResources.resources);
    }
    if (custodyRestrictedResources.operator === 'not-contains') {
        return omit(currenciesEntities, custodyRestrictedResources.resources);
    }
};
export const selectFiatCurrencies = createSelector(selectCurrenciesEntities, selectAccessibleCurrencies, (currenciesEntities, accessibleCurrencies) => accessibleCurrencies.filter((currency) => Boolean(currenciesEntities[currency?.currency]?.fiat)));
export const selectTokenizedFiatCurrencies = createSelector(selectAccessibleCurrencies, selectTokenEntities, (accessibleCurrencies, tokenEntities) => sortCurrencies(accessibleCurrencies.filter((currency) => isOxygenFiat(currency?.currency, tokenEntities))));
export const selectDepositableByBankAccount = createSelector(selectFiatCurrencies, selectTokenizedFiatCurrencies, selectCurrentOrganization, (fiatCurrencies, selectTokenizedFiatCurrencies, selectCurrentOrganization) => {
    if (selectCurrentOrganization.organizationType === 'oxygen') {
        return selectTokenizedFiatCurrencies;
    }
    return sortCurrencies(fiatCurrencies);
});
export const selectPlatformDepositableCryptoCurrencies = createSelector(selectAccessibleCurrencies, selectTokenEntities, (currencies, tokenEntities) => currencies.filter((currency) => {
    return (!currency.fiat &&
        currency.mainCurrencies.some((mainCurrency) => !Boolean(tokenEntities[`${currency.currency}-${mainCurrency}`]?.tags?.includes('oxygen-tokenized-asset'))));
}));
export const selectSecurityCurrencies = createSelector(selectActivePlatformCurrencies, selectTokenEntities, (currencies, tokens) => currencies.filter((currency) => {
    const token = tokens[`${currency.currency}-${currency.mainCurrencies[0]}`];
    return token?.tags?.includes('security-token');
}));
