import { request } from '@copper/utils';
export const getAllCurrencies = () => request.get('/currencies', {
    params: {
        includeNonActive: true
    }
});
export const getActiveCurrencies = () => request.get('/currencies', {
    params: {
        includeNonActive: false
    }
});
export const getTradingFees = (searchParams) => request.get(`/currencies/${searchParams.currency}-${searchParams.mainCurrency}/trading-fees`, {
    params: {
        sendAmount: searchParams.sendAmount
    }
});
