import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AssetIcon, InfoAccount, InfoRow } from '@copper/components';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { NetworkInfo } from '@copper/components/NetworkInfo';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { calculateReportingValue } from '@copper/helpers';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Atom } from '@copper/ui-kit';
import { formatCurrency, formatFiat } from '@copper/utils';
import { mainCurrency, mmfStableCoin } from '../helpers';
import s from './Summary.module.css';
export const OxygenInvestSummary = ({ onSubmit }) => {
    const { getDecimals } = useCurrenciesInfo();
    const currenciesRates = useSelector(selectRates);
    const { getValues, formState: { isSubmitting } } = useFormContext();
    const { portfolio, investType, baseAmount, baseCurrency, quoteCurrency, quoteAmount, marketPrice } = getValues();
    if (!baseCurrency || !quoteCurrency || !baseAmount || !quoteAmount) {
        return;
    }
    const investRate = currenciesRates[baseCurrency?.currency ?? ''] ?? 1;
    const receiveRate = currenciesRates[quoteCurrency?.currency ?? ''] ?? 1;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.container, children: _jsxs("div", { className: s.containerSummary, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: "Order type", titleColor: "ghost", children: investType === 'subscribe' ? 'Subscribe' : 'Redeem' }), _jsx(InfoRow, { title: "Account", titleColor: "ghost", children: _jsx(InfoAccount, { account: portfolio }) }), _jsx(InfoRow, { title: investType === 'subscribe' ? 'Invest' : 'Receive', titleColor: "ghost", children: _jsx(Atom, { align: "right", icon: _jsx(AssetIcon, { currency: quoteCurrency.currency }), title: formatCurrency(quoteAmount, {
                                    decimalPlaces: getDecimals(quoteCurrency.currency, mainCurrency, {
                                        useDictionaryDecimal: true
                                    })
                                }), subtext: formatFiat(calculateReportingValue(quoteAmount, receiveRate)) }) }), _jsx(InfoRow, { title: investType === 'subscribe' ? 'Receive' : 'Redeem', titleColor: "ghost", children: _jsx(Atom, { align: "right", icon: _jsx(AssetIcon, { currency: baseCurrency.currency }), title: formatCurrency(baseAmount, {
                                    decimalPlaces: getDecimals(baseCurrency.currency, mainCurrency, {
                                        useDictionaryDecimal: true
                                    })
                                }), subtext: formatFiat(calculateReportingValue(baseAmount, investRate)) }) }), _jsxs(InfoRow, { title: "Price", titleColor: "ghost", children: [formatCurrency(marketPrice?.price ?? 0, {
                                    decimalPlaces: getDecimals(baseCurrency?.currency ?? ''),
                                    zeroFormat: '0.0000'
                                }), ' ', baseCurrency.currency, " per 1 ", quoteCurrency.currency] }), _jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsx(NetworkInfo, { currency: mmfStableCoin, mainCurrency: mainCurrency }) })] }) }), _jsx(SwitchStateRow, { loading: isSubmitting, text: "Confirm", onClick: onSubmit })] }));
};
