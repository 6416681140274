import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as walletApi from '@copper/api/wallets';
import { normalizeWallets } from '@copper/entities/wallets/wallets-normalize';
export const createWallet = createAsyncThunk('wallet/create', async (data, thunkAPI) => {
    try {
        const response = await walletApi.create(data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const uploadWalletShardKey = createAsyncThunk('wallet/shardKey', async ({ walletId, data }, thunkAPI) => {
    try {
        const response = await walletApi.uploadWalletShardKey(walletId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchWallets = createAsyncThunk('wallets/fetch', async (data) => {
    const params = {
        ...data,
        includeDetails: false
    };
    const response = await walletApi.get(params);
    return normalizeWallets(response.data.wallets);
});
const walletsSlice = createSlice({
    name: 'wallets',
    initialState: {
        wallets: {},
        walletIdsByPortfolioId: {}
    },
    reducers: {
        updateWallets(state, { payload }) {
            payload.forEach((wallet) => {
                if (state.wallets[wallet.walletId]) {
                    state.wallets[wallet.walletId] = wallet;
                }
                else {
                    state.wallets[wallet.walletId] = wallet;
                    if (state.walletIdsByPortfolioId[wallet.portfolioId]) {
                        state.walletIdsByPortfolioId[wallet.portfolioId] = [
                            ...new Set([...state.walletIdsByPortfolioId[wallet.portfolioId], wallet.walletId])
                        ];
                    }
                    else {
                        state.walletIdsByPortfolioId[wallet.portfolioId] = [wallet.walletId];
                    }
                }
            });
        },
        resetWallets(state) {
            state.wallets = {};
            state.walletIdsByPortfolioId = {};
        },
        removeWalletsByPortfolioId(state, { payload }) {
            const walletIds = state.walletIdsByPortfolioId[payload];
            if (Array.isArray(walletIds)) {
                walletIds.forEach((walletId) => {
                    delete state.wallets[walletId];
                });
                delete state.walletIdsByPortfolioId[payload];
            }
        }
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchWallets.fulfilled, (state, { payload }) => {
            state.wallets = {
                ...state.wallets,
                ...payload.wallets
            };
            Object.values(payload.wallets).forEach((wallet) => {
                if (Array.isArray(state.walletIdsByPortfolioId[wallet.portfolioId])) {
                    state.walletIdsByPortfolioId[wallet.portfolioId] = [
                        ...new Set([...state.walletIdsByPortfolioId[wallet.portfolioId], wallet.walletId])
                    ];
                }
                else {
                    state.walletIdsByPortfolioId[wallet.portfolioId] = [wallet.walletId];
                }
            });
        });
        addMatcher(isAnyOf(createWallet.fulfilled, uploadWalletShardKey.fulfilled), (state, { payload }) => {
            state.wallets[payload.walletId] = payload;
            if (Array.isArray(state.walletIdsByPortfolioId[payload.portfolioId])) {
                if (!state.walletIdsByPortfolioId[payload.portfolioId].find((walletId) => walletId === payload.walletId)) {
                    state.walletIdsByPortfolioId[payload.portfolioId].push(payload.walletId);
                }
            }
            else {
                state.walletIdsByPortfolioId[payload.portfolioId] = [payload.walletId];
            }
        });
    }
});
export const { updateWallets, resetWallets, removeWalletsByPortfolioId } = walletsSlice.actions;
export const wallets = walletsSlice.reducer;
