import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { ButtonLink, FormControl, FormLabel, NumberInput } from '@copper/ui-kit';
import { Controller, useController } from 'react-hook-form';
import s from './CollateralAmountLTVInput.module.css';
export const CollateralAmountLTVInput = ({ name, control, label, testId, currency, useCollateralAmount, onToggleClick, calculatedLTVOrAmount }) => {
    const { getDecimals } = useCurrenciesInfo();
    const { field: { onChange } } = useController({
        name,
        control
    });
    const handleOnChange = (value) => {
        onChange(value.value);
    };
    return (_jsx(FormControl, { children: _jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.primary, children: [_jsx(FormLabel, { view: "tool", tooltipView: "tool", tooltipInfo: "The required collateral for this loan. The borrower will need to top up collateral to meet the required amount in the event of a margin call.", rightSection: _jsx(ButtonLink, { size: "s", text: useCollateralAmount ? 'Set LTV' : 'Set amount', onClick: onToggleClick }), children: label }), _jsx(Controller, { control: control, name: name, render: ({ field }) => (_jsx(NumberInput, { value: field.value, view: "tool", size: "l", testId: testId, decimalScale: getDecimals(currency), allowNegative: false, thousandSeparator: true, onValueChange: handleOnChange, placeholder: "0.00", rightSection: useCollateralAmount ? (currency ?? '') : 'Initial LTV %' })) })] }), _jsxs("div", { className: s.secondary, children: [_jsx("span", { className: s.leftSection, children: calculatedLTVOrAmount }), _jsx("span", { className: s.rightSection, children: useCollateralAmount ? 'Initial LTV %' : (currency ?? '') })] })] }) }));
};
