import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.'),
    voteFirst: Yup.string().when('blockchainTransactionType', {
        is: 'register-online',
        then: (voteFirst) => voteFirst.required('Enter a vote first.')
    }),
    voteKey: Yup.string().when('blockchainTransactionType', {
        is: 'register-online',
        then: (voteKey) => voteKey.required('Enter a vote key.')
    }),
    selectionKey: Yup.string().when('blockchainTransactionType', {
        is: 'register-online',
        then: (selectionKey) => selectionKey.required('Enter a selection key.')
    }),
    voteLast: Yup.string().when('blockchainTransactionType', {
        is: 'register-online',
        then: (voteLast) => voteLast.required('Enter a vote last.')
    }),
    voteKeyDilution: Yup.string().when('blockchainTransactionType', {
        is: 'register-online',
        then: (voteKeyDilution) => voteKeyDilution.required('Enter vote key dilution.')
    }),
    stateProofKey: Yup.string().when('blockchainTransactionType', {
        is: 'register-online',
        then: (stateProofKey) => stateProofKey.required('Enter state proof key.')
    })
});
