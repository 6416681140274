import { jsx as _jsx } from "react/jsx-runtime";
import { isSharedOwner } from '@copper/components/AddressBook/Popups/AddressDetails/helpers';
import { IconAddressOwner, IconHouse, Tooltip, fuzzySearch } from '@copper/ui-kit';
export const searchFiatAddresses = (addresses = [], searchQuery) => {
    if (searchQuery === '')
        return addresses;
    return fuzzySearch({
        items: addresses,
        keys: ['bankAccountName'],
        searchQuery
    });
};
export const getOwnerAddress = (owner) => {
    let address = '';
    if (owner.street)
        address = owner.street;
    if (owner.buildingNumber)
        address = address + `, ${owner.buildingNumber}`;
    if (owner.city)
        address = address + `, ${owner.city}`;
    if (owner.postalCode)
        address = address + `, ${owner.postalCode}`;
    return address;
};
export const isVisualProofRequired = (address) => address._embedded?.ownership?.ownershipStatus?.filter((ownership) => ownership.ownerId && ['processing'].includes(ownership.ownershipStatus || '')).length && address.travelRuleInfo?.ownershipVerificationMethod === 'visual-proof';
export const isComplianceReviewRequired = (address) => address.addressInvestigationStatus === 'alerting';
export const getAddressActionColors = ({ address, canUpdate }) => {
    if (address.pendingApproval) {
        if (canUpdate) {
            return { awaitingTextColor: 'oxide', gradientColor: 'oxide' };
        }
        return { awaitingTextColor: 'orange', gradientColor: 'orange' };
    }
    return { awaitingTextColor: 'orange', gradientColor: 'base' };
};
export const getFiltersLength = (filters) => Object.values(filters).reduce((acc, filter) => {
    if (Array.isArray(filter) && !filter.length)
        return acc;
    if (filter)
        return acc + 1;
    return acc;
}, 0);
export const getAcceptTokensProtocol = (asset, network) => {
    if (!asset)
        return;
    const currency = typeof asset === 'string' ? asset : asset.currency;
    if (currency === 'BNB' && network === 'BSC')
        return 'BEP-20';
    if (network === 'ARB' && currency === 'ETH')
        return 'ERC-20';
    if (network === 'OP' && currency === 'ETH')
        return 'OP-20';
    if (network === 'AVAXC' && currency === 'AVAX')
        return 'ARC20';
    if (currency !== network)
        return;
    switch (network) {
        case 'ETH':
            return 'ERC-20';
        case 'MATIC':
            return 'ERC-20';
        case 'AVAXC':
            return 'ARC-20';
        case 'FTM':
            return 'FRC-20';
        case 'TRX':
            return 'TRC-20';
        case 'POL':
            return 'ERC-20';
    }
};
export const getAdminAddressStatusColor = (address) => {
    switch (address?.verificationStatus) {
        case 'verified':
            return {
                status: 'Verified',
                color: 'green'
            };
        case 'processing':
        case 'action-required':
            return {
                status: 'Processing',
                color: 'orange'
            };
        case 'rejected':
            return {
                status: 'Rejected',
                color: 'red'
            };
        case 'unverified':
            return {
                status: 'Not verified',
                color: 'red'
            };
        default:
            return {
                status: address?.verificationStatus?.split('-').join(' '),
                color: 'base'
            };
    }
};
export const AVAILABLE_VERIFICATION_STATUSES = [
    'not-required',
    'unverified',
    'processing',
    'action-required',
    'verified',
    'owner-information-missing'
];
export const getOwnerIcon = ({ owner, organizationId }) => {
    if (owner.ownerType === 'client' && organizationId === owner.organizationId)
        return (_jsx(Tooltip, { label: 'My organisation', placement: 'top', children: _jsx(IconHouse, { width: 16, height: 16 }) }));
    if (owner.ownerType === 'third-party' && organizationId === owner.organizationId)
        return (_jsx(Tooltip, { label: 'Saved owner', placement: 'top', children: _jsx(IconAddressOwner, { width: 16, height: 16 }) }));
    if (isSharedOwner(owner)) {
        return null;
    }
};
