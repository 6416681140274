import { request } from '@copper/utils';
const normalizeLoansParams = ({ statuses, ...params }) => ({
    ...params,
    status: statuses?.join(',')
});
export const createAgencyLoan = ({ organizationId, data }) => request.post(`/organizations/${organizationId}/loans`, data);
export const getLoans = ({ params, organizationId }) => request.get(`/organizations/${organizationId}/loans`, {
    params: normalizeLoansParams(params)
});
export const getLoanById = ({ params, loanId, organizationId }) => request.get(`/organizations/${organizationId}/loans/${loanId}`, { params });
export const approveLoan = (organizationId, loanId) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.approve-loan+json'
    }
});
export const rejectLoan = (organizationId, loanId) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.reject-loan+json'
    }
});
export const acceptLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan+json'
    }
});
export const cancelPrincipalLoan = (organizationId, loanId) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan+json'
    }
});
export const addCollateral = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.add-collateral+json'
    }
});
export const repayLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.repay-loan+json'
    }
});
export const payLoanInterest = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.pay-loan-interest+json'
    }
});
export const acceptInterestPaymentRequest = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-interest-payment+json'
    }
});
export const acceptLoanRepaymentRequest = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-repayment+json'
    }
});
export const cancelLoanRepaymentRequest = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-repayment+json'
    }
});
export const cancelInterestPaymentRequest = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-interest-payment+json'
    }
});
export const rebalanceLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.rebalance-loan+json'
    }
});
export const estimateLoanLtv = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.estimate-loan-ltv+json'
    }
});
export const estimateLoanLeverageRatio = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.estimate-loan-leverage-ratio+json'
    }
});
export const topUpLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.top-up-loan+json'
    }
});
export const defaultLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.default-loan+json'
    }
});
export const acceptLoanTopUpAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-top-up+json'
    }
});
export const acceptLoanReleaseCollateralAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-rebalance+json'
    }
});
export const cancelLoanTopUpAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-top-up+json'
    }
});
export const cancelLoanReleaseCollateralAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-rebalance+json'
    }
});
export const confirmExternalDisbursement = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.confirm-external-disbursement+json'
    }
});
export const confirmExternalTopup = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.confirm-external-top-up+json'
    }
});
export const confirmExternalRepayment = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.confirm-external-repayment+json'
    }
});
export const confirmExternalInterestPayment = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.confirm-external-interest-payment+json'
    }
});
export const rerateLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.rerate-loan+json'
    }
});
export const extendTermLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.extend-loan-term+json'
    }
});
export const acceptLoanRerate = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-rerate+json'
    }
});
export const cancelLoanRerate = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-rerate+json'
    }
});
export const acceptLoanTermExtension = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-term-extension+json'
    }
});
export const cancelLoanTermExtension = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-term-extension+json'
    }
});
export const calculateLTV = (organizationId, data) => request.patch(`/organizations/${organizationId}/loans/`, data, {
    headers: {
        'Content-Type': 'application/vnd.calculate-ltv-dry-run+json'
    }
});
