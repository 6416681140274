import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { selectActivePlatformCurrencies } from '@copper/entities/currencies/currencies-selector';
import { selectClearloopTokens } from '@copper/entities/dictionaries/dictionaries-selector';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { selectGroupedPortfolios, selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClearLoopForm } from './Form';
import { ClearloopGuide } from './Guide';
import { Marketing } from './Marketing';
import { Result } from './Result';
import { Summary } from './Summary';
import { validationSchema } from './helpers';
import s from './Clearloop.module.css';
const stepMap = new Map([
    ['form', '1'],
    ['summary', '2'],
    ['result', '3']
]);
export const Clearloop = () => {
    const dispatch = useDispatch();
    const { route } = useRoutingContext();
    const { setTopPanel } = useToolbar();
    const portfolios = useSelector(selectPortfolios);
    const { clearloopPortfolios } = useSelector(selectGroupedPortfolios);
    const activeCurrencies = useSelector(selectActivePlatformCurrencies);
    const groupedPortfolios = useSelector(selectGroupedPortfolios);
    const clearloopTokenNetworks = useSelector(selectClearloopTokens);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [transaction, setTransaction] = useState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState();
    const hasClearLoop = Boolean(groupedPortfolios.clearloopPortfolios.length);
    const [state, send] = useStateMachine({
        initial: hasClearLoop ? 'form' : 'default',
        states: {
            default: {
                on: { SHOW_FORM: 'form', SHOW_GUIDE: 'guide' }
            },
            form: {
                on: { NEXT_STEP: 'summary', SHOW_GUIDE: 'guide' }
            },
            summary: {
                on: { BACK_STEP: 'form', NEXT_STEP: 'result', SHOW_GUIDE: 'guide' }
            },
            result: {
                on: { RESET: 'form', SHOW_GUIDE: 'guide' }
            },
            guide: {
                on: { BACK_TO_DEFAULT: 'default', BACK_TO_FORM: 'form' }
            }
        }
    });
    const form = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            clearLoopType: 'lock',
            clearLoopClientAccountType: 'exchange'
        }
    });
    const { getValues, watch } = form;
    const { origin } = watch();
    useEffect(() => {
        if (route?.name === 'clearloop') {
            const data = route?.data;
            const getDefaultPortfolio = () => {
                if (data?.exchangeId) {
                    const exchangePortfolios = clearloopPortfolios.filter((portfolio) => portfolio.extra?.exchange === data.exchangeId);
                    return exchangePortfolios.length === 1 ? exchangePortfolios[0] : undefined;
                }
                return portfolios[data?.portfolioId ?? ''];
            };
            const defaultValues = {
                clearLoopType: data?.clearLoopType ?? 'lock',
                currency: activeCurrencies.find((item) => item.currency === data?.currencyId),
                origin: getDefaultPortfolio(),
                clearLoopClientAccountType: 'exchange',
                amount: data?.amount
            };
            send({ type: 'RESET' });
            form.reset(defaultValues);
        }
    }, [route]);
    useEffect(() => {
        let subTitle = '';
        if (['form', 'summary', 'result'].includes(state.value)) {
            subTitle = `Step ${stepMap.get(state.value)} of ${stepMap.size}`;
        }
        if (state.value === 'result') {
            const { clearLoopType } = getValues();
            const resultSubTitle = clearLoopType === 'lock' ? 'Assets delegated' : 'Undelegating';
            subTitle = transactionErrorMessage ? 'Failed' : resultSubTitle;
        }
        setTopPanel({
            title: state.value !== 'guide' ? 'ClearLoop' : 'ClearLoop guide',
            subTitle,
            showGuideText: state.value !== 'guide' ? 'ClearLoop guide' : undefined,
            onBack: state.value === 'summary' ? () => send({ type: 'BACK_STEP' }) : undefined,
            showGuide: () => send({ type: 'SHOW_GUIDE' }),
            onCloseClickOverride: state.value === 'guide'
                ? () => send({ type: hasClearLoop ? 'BACK_TO_FORM' : 'BACK_TO_DEFAULT' })
                : undefined
        });
    }, [state, send, setTopPanel, transactionErrorMessage, getValues]);
    const handleFormSubmit = () => {
        send({ type: 'NEXT_STEP' });
    };
    const getPortfolioIdsByType = (type) => {
        if (!origin) {
            return {
                portfolioId: '',
                toPortfolioId: ''
            };
        }
        const typeMapping = {
            lock: {
                portfolioId: origin?.extra?.parentPortfolioId || '',
                toPortfolioId: origin?.portfolioId
            },
            unlock: {
                portfolioId: origin?.portfolioId,
                toPortfolioId: origin?.extra?.parentPortfolioId || ''
            }
        };
        return typeMapping[type];
    };
    const handleFormSend = async () => {
        const { currency, amount, clearLoopType, clearLoopClientAccountType, description } = getValues();
        if (!currency || !amount) {
            return;
        }
        const mainCurrency = clearloopTokenNetworks?.find((token) => token.baseCurrency === currency.currency)
            ?.mainCurrency ?? currency.currency;
        const data = {
            externalOrderId: cuid(),
            amount,
            baseCurrency: currency?.currency,
            mainCurrency,
            orderType: 'withdraw',
            portfolioId: getPortfolioIdsByType(clearLoopType).portfolioId,
            toPortfolioId: getPortfolioIdsByType(clearLoopType).toPortfolioId,
            description,
            clearLoopClientAccountType
        };
        setIsSubmitting(true);
        setTransactionErrorMessage(undefined);
        try {
            const createdOrder = await dispatch(createOrder(data)).unwrap();
            setTransaction(createdOrder);
        }
        catch (err) {
            setTransactionErrorMessage(getErrorData(err).message);
        }
        finally {
            setIsSubmitting(false);
            send({ type: 'NEXT_STEP' });
        }
    };
    const handleDone = () => {
        form.reset();
        send('RESET');
    };
    return (_jsxs("div", { className: s.container, children: [state.value === 'default' && _jsx(Marketing, {}), state.value === 'form' && form && _jsx(ClearLoopForm, { onSubmit: handleFormSubmit, form: form }), state.value === 'summary' && (_jsx(Summary, { isSubmitting: isSubmitting, data: { ...getValues() }, onNext: handleFormSend })), state.value === 'result' && (_jsx(Result, { formData: { ...getValues() }, transaction: transaction, errorMessage: transactionErrorMessage, onDone: handleDone })), state.value === 'guide' && _jsx(ClearloopGuide, {})] }));
};
