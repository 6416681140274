import { jsx as _jsx } from "react/jsx-runtime";
import { AssetIcon } from '@copper/components';
export const STAKING_METHODS_MAP = {
    ETH: [
        {
            icon: _jsx(AssetIcon, { currency: "ETH" }),
            method: 'direct',
            title: 'Direct staking',
            subTitle: 'Stake ETH through one of our staking partners. Your staked assets will be locked while staking. Min: 32 ETH.'
        },
        {
            icon: _jsx(AssetIcon, { currency: "LDO" }),
            method: 'liquid',
            title: 'Liquid staking',
            subTitle: 'Stake ETH through Lido and receive stETH while staking. stETH can be traded, transferred, or used in DeFi. No min.'
        }
    ]
};
