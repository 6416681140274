import { request } from '@copper/utils';
export const createAssetRequest = (data) => request.post('/token-onboarding', data);
export const reviewOnboardingRequest = (data) => request.patch('/token-onboarding', data, {
    headers: {
        'Content-Type': 'application/vnd.review-token-onboarding+json'
    }
});
export const getOnboardingRequests = (params) => request.get(`/token-onboarding`, {
    params
});
export const confirmRequestGeofence = (requestId) => request.patch(`/token-onboarding`, {
    requestId
}, {
    headers: {
        'Content-Type': 'application/vnd.token-onboarding-geofencing-confirmed+json'
    }
});
