import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { IconArrowForward, Skeleton } from '@copper/ui-kit';
import s from './SettingsItemLink.module.css';
export const SettingsItemLink = ({ to, disabled, rightSection, children, onClick, skeleton = false, noRounding, form = 'detached', view = 'default', leftSection, lightArrow }) => {
    if (skeleton) {
        return (_jsxs("div", { className: cn(s.item, s[`form_${form}`], s[`view_${view}`], s.skeletonContainer, {
                [s.noRounding]: noRounding
            }), children: [_jsx("div", { className: s.content, children: children }), _jsx(Skeleton, { width: 32, height: 32, variant: "circle" })] }));
    }
    if (disabled) {
        return (_jsx("div", { className: cn(s.item, s[`form_${form}`], s[`view_${view}`], { [s.noRounding]: noRounding }), children: _jsx("div", { className: s.content, children: children }) }));
    }
    if (!to) {
        return (_jsxs("div", { onClick: onClick, className: cn(s.item, s[`form_${form}`], s[`view_${view}`], { [s.noRounding]: noRounding }), children: [leftSection, _jsx("div", { className: s.content, children: children }), rightSection && _jsx("div", { children: rightSection }), _jsx("div", { className: cn(s.arrowIcon, { [s.lightArrow]: lightArrow }), children: _jsx(IconArrowForward, {}) })] }));
    }
    return (_jsxs(NavLink, { to: to, className: cn(s.item, s[`form_${form}`], s[`view_${view}`], { [s.noRounding]: noRounding }), children: [leftSection, _jsx("div", { className: s.content, children: children }), rightSection && _jsx("div", { children: rightSection }), _jsx("div", { className: s.arrowIcon, children: _jsx(IconArrowForward, {}) })] }));
};
