import { ADA } from './ADA';
import { APT } from './APT';
import { ATOM } from './ATOM';
import { AVAX } from './AVAX';
import { AXL } from './AXL';
import { BLD } from './BLD';
import { BNB } from './BNB';
import { BTC } from './BTC';
import { CCD } from './CCD';
import { CORE } from './CORE';
import { DIVI } from './DIVI';
import { DOT } from './DOT';
import { ETH } from './ETH';
import { HBAR } from './HBAR';
import { ICP } from './ICP';
import { INJ } from './INJ';
import { KSM } from './KSM';
import { NEAR } from './NEAR';
import { ROSE } from './ROSE';
import { SOL } from './SOL';
import { STX } from './STX';
import { SUI } from './SUI';
import { TON } from './TON';
import { WFLR } from './WFLR';
import { XRD } from './XRD';
import { ZIL } from './ZIL';
export const assets = [
    ADA,
    APT,
    ATOM,
    AVAX,
    AXL,
    BLD,
    BNB,
    BTC,
    CCD,
    CORE,
    DIVI,
    DOT,
    ETH,
    HBAR,
    ICP,
    INJ,
    KSM,
    NEAR,
    ROSE,
    SOL,
    STX,
    SUI,
    TON,
    WFLR,
    XRD,
    ZIL
];
