import { keyBy } from 'lodash-es';
import * as orders from '@copper/api/orders';
import { normalizeUser } from '../user/user-normalize';
export const createDryRunOrder = (params) => orders.dryRun(params).then((response) => response.data);
export const fetchOrdersWithSettlementTotals = ({ organizationId, portfolioId, counterpartyId }) => orders
    .get({
    organizationIds: [organizationId],
    ...(portfolioId && { portfolioId }),
    includeSettlementTotals: true,
    counterpartyId
})
    .then((response) => response.data);
export const fetchOrdersWithStatus = (organizationId, portfolioId, status) => orders
    .get({
    organizationIds: [organizationId],
    portfolioIds: portfolioId ? [portfolioId] : undefined,
    statuses: status ? [status] : undefined,
    limit: '2000'
})
    .then((response) => response.data);
export const fetchOrder = (orderId) => orders.getById(orderId).then((response) => response.data);
export const rejectTravelRules = (data, orderId) => orders.rejectOrder(data, orderId).then((response) => response.data);
export const fetchOrderChanges = (orderId) => orders.getChangesById(orderId, '?includeUsers=true').then((response) => {
    const { changes, _embedded: { users = [] } = {} } = response.data;
    const usersById = keyBy(users, 'userId');
    return changes
        .map((orderChange) => {
        const user = usersById[orderChange.changedBy];
        return user ? { ...orderChange, user: normalizeUser(user) } : orderChange;
    })
        .reverse();
});
export const enterWithdrawalPassword = ({ orderId, masterPassword }) => orders.enterMasterPassword({ orderId, masterPassword }).then((response) => response.data);
export const bulkSettlementOperation = ({ orderIds, type }) => orders.bulkSettleOperation({ orderIds, type }).then((response) => response.data);
export const bulkCoSignOperation = ({ orderIds, masterPassword }) => orders.bulkCoSignOperation({ orderIds, masterPassword }).then((response) => response.data);
export const createBoundedToken = (orderId) => orders.createBoundedToken(orderId).then((response) => ({
    token: response.data.token,
    correlationId: response.config.headers['Correlation-Id']
}));
export const chargeExecutionFees = (orderId) => orders.chargeExecutionFees(orderId).then((response) => response.data);
export const recreateOrder = (orderId) => orders.recreateOrder(orderId).then((response) => response.data);
export const fetchTransactionFlowSummary = (params) => orders.getTransactionFlowSummary(params).then(({ data }) => data.summaries);
export const orderReplaceByFee = (params) => orders.replaceByFee(params).then((response) => response.data);
