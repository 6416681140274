import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
import { canImportanceTransfer, canStakeDelegation, canStakeNomination, canStakeWithAmount, canStakeWithCycles, canStakeWithRewardAddress } from '@copper/helpers/stake';
import { validateAmountField } from '@copper/helpers/validation';
import figment from './icons/figment.svg';
import kiln from './icons/kiln.svg';
import p2p from './icons/p2p.svg';
import yuma from './icons/yuma.svg';
export const getPoolOperationList = (currency) => {
    let poolOperationList = [
        {
            label: 'Copper',
            value: 'selectPredefined'
        }
    ];
    if (currency?.currency === 'ETH') {
        if (currency?.extra?.stakingProviders?.find(({ name }) => name === 'Figment')) {
            poolOperationList.push({
                label: 'Figment',
                value: 'figment'
            });
        }
    }
    else {
        poolOperationList.push({
            label: 'Set address',
            value: 'connect'
        });
    }
    if (currency?.currency === 'SOL') {
        poolOperationList.push({
            label: 'Marinade Native',
            value: 'marinade'
        });
    }
    if (currency?.currency === 'TAO') {
        poolOperationList = [
            {
                label: 'P2P',
                value: 'p2p'
            },
            {
                label: 'YUMA',
                value: 'yuma'
            }
        ];
    }
    return poolOperationList;
};
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().shape({
        portfolioId: Yup.string().required('Select an account.')
    }),
    amount: Yup.string()
        .when('currency', {
        is: (currency) => canStakeWithAmount(currency),
        then: validateAmountField
    })
        .when('minStake', {
        is: (minStake) => minStake,
        then: (schema) => schema.test({
            name: 'amount',
            test: (value, { parent, createError }) => {
                const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
                const minStake = parent?.minStake;
                if (new BigNumber(value ?? 0).minus(minStake ?? 0).gte(0)) {
                    return true;
                }
                return createError({
                    message: `The minimum you can stake is ${minStake} ${currency}.`
                });
            }
        })
    })
        .when('maxStake', {
        is: (maxStake) => maxStake,
        then: (schema) => schema.test({
            name: 'amount',
            test: (value, { parent, createError }) => {
                const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
                const maxStake = parent?.maxStake;
                if (new BigNumber(value ?? 0).minus(maxStake ?? 0).lte(0)) {
                    return true;
                }
                return createError({
                    message: `The maximum you can stake is ${maxStake} ${currency}.`
                });
            }
        })
    })
        .test('amountIncrementValidation', 'Amount must be multiple of 32 ETH.', (value, { parent }) => {
        const currency = parent?.currency?.currency ?? parent?.baseCurrency ?? '';
        const method = parent?.method;
        if (currency === 'ETH' &&
            method !== 'liquid' &&
            new BigNumber(value ?? 0).mod(32).toNumber()) {
            return false;
        }
        return true;
    }),
    poolId: Yup.string().when(['currency', 'predefinedPool'], {
        is: (currency, predefinedPool) => canStakeDelegation(currency) &&
            !canStakeNomination(currency) &&
            !predefinedPool &&
            !['ETH', 'TAO'].includes(currency?.currency),
        then: (schema) => schema.required('Enter a validator address.')
    }),
    remoteAccountPublicKey: Yup.string().when('currency', {
        is: (currency) => canImportanceTransfer(currency),
        then: (schema) => schema.required('Enter a remote account public key.')
    }),
    period: Yup.number().when('currency', {
        is: (currency) => canStakeWithCycles(currency),
        then: (schema) => schema
            .required('Enter a number of cycles.')
            .min(1, 'Value must be greater or equal to 1')
            .max(12, 'Value must be less or equal to 12')
    }),
    rewardAddress: Yup.string().when('currency', {
        is: (currency) => canStakeWithRewardAddress(currency),
        then: (schema) => schema.required('Enter a reward address.')
    }),
    validatorProvider: Yup.object().when('poolOperation', {
        is: (poolOperation) => poolOperation === 'selectValidator',
        then: (schema) => schema.required('Select a validator provider.')
    })
});
export const getBlockchainTransactionType = (currency, method, poolOperation) => {
    if (canImportanceTransfer(currency))
        return 'importance-transfer';
    if (method === 'liquid')
        return 'mint';
    if (poolOperation === 'marinade')
        return 'stake-nomination';
    return 'stake-delegation';
};
export const getRewardCurrency = (currency) => {
    return currency.extra?.rewardCurrency ?? currency.mainCurrency;
};
export const getValidatorProviders = (currency, validatorProviders) => validatorProviders?.filter((validatorProvider) => validatorProvider?.availableCurrencies?.find((availableCurrency) => availableCurrency?.currency === currency?.currency));
// We're moving to dynamic validator providers and the plan is to progressively move each currency one by one.
// Currently only Stacks (STX) is migrated to the new staking structure.
export const isNewStakingStructure = (currency, validatorProviders) => validatorProviders?.some((validatorProvider) => validatorProvider?.availableCurrencies?.some((availableCurrency) => availableCurrency?.currency === currency?.currency));
export const mapValidatorProviderToLogo = (validatorProvider) => {
    const { name } = validatorProvider ?? {};
    switch (name) {
        case 'Figment':
            return figment;
        case 'Kiln':
            return kiln;
        case 'P2P':
            return p2p;
        case 'YUMA':
            return yuma;
    }
};
