import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { portfolioIcons } from './icons';
import s from './PortfolioIcon.module.css';
const PortfolioIcon = ({ portfolio, size = 's' }) => {
    let iconKey;
    if (typeof portfolio === 'string') {
        iconKey = portfolio;
    }
    else {
        iconKey = ['external', 'clearloop', 'csl-pledge'].includes(portfolio.portfolioType)
            ? portfolio.extra?.exchange || 'fallback'
            : portfolio.portfolioType;
    }
    const componentIcon = portfolioIcons[iconKey] || portfolioIcons['fallback'];
    if (componentIcon) {
        return _jsx("img", { className: s[`size_${size}`], src: componentIcon, alt: "" });
    }
    return null;
};
export { PortfolioIcon };
