import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as tokenOnboardingApi from '@copper/api/tokenOnboarding';
const initialState = {
    requests: []
};
export const fetchTokenOnboardingRequests = createAsyncThunk('tokenOnboarding/fetchTokenOnboardingRequests', async (params, thunkAPI) => {
    try {
        const response = await tokenOnboardingApi.getOnboardingRequests(params);
        return response.data.tokenOnboardings;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const reviewTokenOnboardingRequest = createAsyncThunk('tokenOnboarding/reviewTokenOnboardingRequest', async (params, thunkAPI) => {
    try {
        const response = await tokenOnboardingApi.reviewOnboardingRequest(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const confirmRequestGeofence = createAsyncThunk('tokenOnboarding/confirmRequestGeofence', async (requestId, thunkAPI) => {
    try {
        const response = await tokenOnboardingApi.confirmRequestGeofence(requestId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const tokenOnboardingSlice = createSlice({
    name: 'tokenOnboarding',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchTokenOnboardingRequests.fulfilled, (state, action) => {
            state.requests = action.payload;
        });
        addCase(reviewTokenOnboardingRequest.fulfilled, (state, action) => {
            state.requests = state.requests.map((request) => {
                if (request.requestId === action.payload.requestId)
                    return action.payload;
                return request;
            });
        });
        addCase(confirmRequestGeofence.fulfilled, (state, action) => {
            state.requests = state.requests.map((request) => {
                if (request.requestId === action.payload.requestId)
                    return action.payload;
                return request;
            });
        });
    }
});
const { reducer } = tokenOnboardingSlice;
export const tokenOnboarding = reducer;
