export const getStakePayoutPeriod = (currency) => currency?.extra?.stakePayoutPeriod;
export const getStakeUnboundPeriod = (currency) => currency?.extra?.stakeUnboundPeriod;
export const getMinimumStake = (currency) => {
    if (currency?.currency === 'ETH') {
        return '32'; // It needs to be moved to BE
    }
    return currency?.extra?.minimumStake;
};
export const getMaximumStake = (currency) => {
    if (currency?.currency === 'ETH') {
        return currency?.extra?.maxDelegatorStake;
    }
    // It needs to be implemented on BE
    // return currency?.extra?.maximumStake;
};
export const canAddStakeAmountToPool = (mainCurrency) => !['ADA', 'STX', 'BSC'].includes(mainCurrency ?? '');
// Currency: ROSE, DIVI, ZIL, ETH
export const canStakeWithAmount = (currency) => currency?.tags?.includes('stake-with-amount');
// Currency: ADA, DIVI, ROSE, SOL, ZIL
export const canStakeDelegation = (currency) => currency?.tags?.includes('stake-delegation');
// Currency: ETH
export const inGenerateForm = (currency) => currency?.tags?.includes('pool-creation-with-generate-form');
// Currency: XEM
export const canImportanceTransfer = (currency) => currency?.tags?.includes('importance-transfer');
// Currency: AVAX, DOT, KSM, SOL
export const canStakeNomination = (currency) => currency?.tags?.includes('stake-nomination');
// Currency: DOT, KSM, ICP
export const canStakeNominationWithMultiplePools = (currency) => currency?.tags?.includes('stake-nomination-multiple-pools');
// Currency: AVAX
export const canStakeWithPeriod = (currency) => currency?.tags?.includes('stake-with-period');
// Currency: AVAX
export const hasProofOfPossession = (currency) => currency?.tags?.includes('pool-creation-proof-of-possession');
export const canTransferStake = (currency) => currency?.tags?.includes('transfer-stake');
// ATOM
export const canTransferStakeWithAmount = (currency) => currency?.tags?.includes('transfer-stake-with-amount');
// ATOM, AVAX, AXL, BLD, BNB, DOT, INJ, KSM, LUNA, NEAR, ROSE, SOL, ZETA, ZIL
export const canUnstakeWithAmount = (currency) => currency?.tags?.includes('unstake-with-amount');
// STX
export const canStakeWithCycles = (currency) => currency?.tags?.includes('stake-with-cycles');
// STX, BTC(CORE)
export const canStakeWithRewardAddress = (currency) => currency?.tags?.includes('stake-with-reward-address');
// ADA, TON
export const hasPendingPoolCalculation = (currency) => currency?.tags?.includes('pending-pool-calculation');
// BTC, ICP
export const hasEndTime = (currency) => currency?.tags?.includes('stake-with-dissolve');
// DOT, KSM
export const canDelegateStakingOperations = (currency) => currency?.tags?.includes('delegate-staking-operations');
