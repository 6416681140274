import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStateMachine from '@cassiozen/usestatemachine';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import cuid from 'cuid';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { getErrorData } from '@copper/utils';
import { OxygenInvestForm } from './Form';
import { OxygenInvestSummary } from './Summary';
import { validationSchema } from './helpers';
import { OxygenInvestResult } from './Result';
import s from './OxygenInvest.module.css';
export const OxygenInvest = () => {
    const { setTopPanel } = useToolbar();
    const dispatch = useDispatch();
    const [transaction, setTransaction] = useState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState();
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            investType: 'subscribe'
        }
    });
    const { handleSubmit } = form;
    const [state, send] = useStateMachine({
        initial: 'investForm',
        states: {
            investForm: {
                on: {
                    summary: 'summary'
                }
            },
            summary: {
                on: {
                    back: 'investForm',
                    result: 'result'
                }
            },
            result: { on: { reset: 'investForm' } }
        }
    });
    useEffect(() => {
        setTopPanel({
            title: 'Invest',
            onBack: state.value === 'summary' ? () => send({ type: 'back' }) : undefined
        });
    }, [send, setTopPanel, state.value]);
    const handleFormSend = form.handleSubmit(async (values) => {
        const { portfolio, investType, baseAmount, baseCurrency, quoteCurrency, quoteAmount } = values;
        if (!baseCurrency || !quoteCurrency || !baseAmount || !quoteAmount) {
            return;
        }
        const data = {
            externalOrderId: cuid(),
            orderType: investType === 'subscribe' ? 'buy-mmf-tokens' : 'sell-mmf-tokens',
            portfolioId: portfolio.portfolioId,
            baseCurrency: baseCurrency?.currency,
            mainCurrency: 'ETH',
            amount: quoteAmount,
            quoteCurrency: quoteCurrency.currency,
            quoteMainCurrency: 'ETH'
        };
        setTransactionErrorMessage(undefined);
        try {
            const result = await dispatch(createOrder(data)).unwrap();
            setTransaction(result);
        }
        catch (err) {
            setTransactionErrorMessage(getErrorData(err).message);
        }
        finally {
            send('result');
        }
    });
    const handleDone = () => {
        form.reset();
        send('reset');
    };
    return (_jsx("div", { className: s.container, children: _jsx("div", { className: s.content, children: _jsxs(FormProvider, { ...form, children: [state.value === 'investForm' && (_jsx(OxygenInvestForm, { onNext: handleSubmit(() => send('summary')) })), state.value === 'summary' && _jsx(OxygenInvestSummary, { onSubmit: handleFormSend }), state.value === 'result' && (_jsx(OxygenInvestResult, { transaction: transaction, onDone: handleDone, errorMessage: transactionErrorMessage }))] }) }) }));
};
