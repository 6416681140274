import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
import { isLoanWithCollateralType } from '@copper/components/Loans/helpers';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.'),
    loanType: Yup.string().required('Select an loan type.'),
    amount: Yup.string()
        .required('Enter a loan amount.')
        .test('amount', (value = '0', { parent, createError }) => {
        const { availableBalance = '0', currency } = parent;
        return new BigNumber(availableBalance).minus(value).gte(0)
            ? true
            : createError({
                message: `Insufficient ${currency?.currency ?? ''} balance.`
            });
    }),
    parentLoan: Yup.object().required('Select a parent loan.'),
    counterparty: Yup.object().required('Select a borrower.'),
    currency: Yup.object().required('Select an asset.'),
    openTerm: Yup.boolean(),
    maturityDate: Yup.string().when('openTerm', {
        is: (openTerm) => !openTerm,
        then: (schema) => schema.required('Select a loan term.')
    }),
    collateralAmountOrTargetLTV: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a collateral amount or a target LTV.')
    }),
    collateralMainCurrency: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Select a network.')
    }),
    interestRateBps: Yup.string().required('Enter an interest rate.'),
    rebalancePercent: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a rebalance rate.')
    }),
    marginCallPercent: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a margin call rate.')
    }),
    defaultPercent: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a default rate.')
    }),
    lenderFeeShare: Yup.string().required('Enter the lender fee share.'),
    useLenderFeeSharePercentage: Yup.boolean()
        .test('useLenderFeeSharePercentage', (_, { parent, createError }) => {
        if (parent.useLenderFeeSharePercentage) {
            return parent.lenderFeeShare && new BigNumber(parent.lenderFeeShare).isGreaterThan(100)
                ? createError({
                    message: "The lender's share cannot be higher than 100%"
                })
                : true;
        }
        else {
            return parent.lenderFeeShare &&
                new BigNumber(parent.lenderFeeShare).isGreaterThanOrEqualTo(parent.interestRateBps)
                ? createError({
                    message: "The interest rate must be higher than the lender's share"
                })
                : true;
        }
    })
});
