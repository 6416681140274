import { createSelector } from '@reduxjs/toolkit';
export const selectExchangesEntities = (store) => store.dictionaries.exchanges.entities;
export const selectClearloopExchangesEntities = (store) => store.dictionaries.clearloopExchanges.entities;
export const selectEnabledClearloopIds = createSelector(selectClearloopExchangesEntities, (clearloopExchangesEntities) => Object.values(clearloopExchangesEntities)
    .filter((clearloopExchange) => !clearloopExchange?.disabled)
    .map((clearloopExchange) => clearloopExchange?.exchangeId));
export const selectExchanges = createSelector(selectExchangesEntities, (exchangesEntities) => {
    const exchanges = Object.values(exchangesEntities);
    return exchanges.reduce((acc, exchange) => {
        const onlyWgExchangeAccounts = exchange.exchangeAccounts.filter((exchangeAccount) => exchangeAccount.accountType !== 'clearloop' &&
            !exchangeAccount.disabled &&
            !exchange.disabled);
        if (onlyWgExchangeAccounts.length > 0) {
            acc.push({
                ...exchange,
                exchangeAccounts: onlyWgExchangeAccounts.sort((a) => a.fields?.some((field) => field.name === 'parentPortfolioId') ? 1 : -1)
            });
        }
        return acc;
    }, []);
});
export const selectClearloopExchanges = createSelector(selectExchangesEntities, (exchangesEntities) => {
    const exchanges = Object.values(exchangesEntities);
    return exchanges.reduce((acc, exchange) => {
        const clearloopExchangeAccount = exchange.exchangeAccounts.find((exchangeAccount) => exchangeAccount.accountType === 'clearloop' &&
            !exchangeAccount.disabled &&
            !exchange.disabled);
        if (clearloopExchangeAccount) {
            acc.push({
                ...exchange,
                exchangeAccounts: [clearloopExchangeAccount]
            });
        }
        return acc;
    }, []);
});
export const selectPledgeExchanges = createSelector(selectExchangesEntities, (exchangesEntities) => {
    const exchanges = Object.values(exchangesEntities);
    return exchanges.reduce((acc, exchange) => {
        const pledgeExchangeAccount = exchange.exchangeAccounts.find((exchangeAccount) => exchangeAccount.accountType === 'csl-pledge' &&
            !exchangeAccount.disabled &&
            !exchange.disabled);
        if (pledgeExchangeAccount) {
            acc.push({
                ...exchange,
                exchangeAccounts: [pledgeExchangeAccount]
            });
        }
        return acc;
    }, []);
});
export const selectAdminClearloopExchanges = createSelector(selectExchangesEntities, (exchangesEntities) => {
    const exchanges = Object.values(exchangesEntities);
    return exchanges.reduce((acc, exchange) => {
        const clearloopExchangeAccount = exchange.exchangeAccounts.find((exchangeAccount) => exchangeAccount.accountType === 'clearloop' &&
            !exchangeAccount.disabled &&
            !exchangeAccount.unlockDisabledFor);
        if (clearloopExchangeAccount) {
            acc.push({
                ...exchange,
                exchangeAccounts: [clearloopExchangeAccount]
            });
        }
        return acc;
    }, []);
});
