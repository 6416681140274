import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@copper/ui-kit';
import React from 'react';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { sendTrackingEvent } from '@copper/utils';
import { useSelector } from 'react-redux';
import s from './Marketing.module.css';
import desk from './img/desk.svg';
import efficiency from './img/efficiency.svg';
import eye from './img/eye.svg';
import marginLending from './img/margin-lending.svg';
import network from './img/network.svg';
const ListItem = ({ title, subTitle, icon }) => (_jsxs("li", { className: s.listItem, children: [_jsx("div", { className: s.itemIcon, children: icon && _jsx("img", { src: icon, className: s.itemIcon, alt: "" }) }), _jsxs("div", { className: s.itemContent, children: [_jsx("p", { className: s.itemTitle, children: title }), _jsx("p", { className: s.itemSubTitle, children: subTitle })] })] }));
const marketingList = [
    {
        title: 'Dedicated Financing Desk',
        subTitle: 'Leverage our expertise to optimise lending strategies whilst reducing risk.',
        icon: desk
    },
    {
        title: 'Fully managed lifecycle',
        subTitle: 'Copper executes and manages the entire loan lifecycle to maximise efficiency.',
        icon: efficiency
    },
    {
        title: 'Access our network of 400+ institutions ',
        subTitle: 'And receive regular market updates using data aggregated across our network.',
        icon: network
    },
    {
        title: 'Flexible structures',
        subTitle: 'Multiple loan types and a full suite of collateral structures.',
        icon: marginLending
    },
    {
        title: 'Real-time visibility into loan health',
        subTitle: 'And loan use through a dedicated dashboard.',
        icon: eye
    }
];
export const Marketing = () => {
    const { organizationId } = useSelector(selectCurrentOrganization);
    return (_jsx("div", { className: s.container, children: _jsxs("div", { className: s.content, children: [_jsx("div", { className: s.featureLending }), _jsx("h2", { className: s.title, children: "Unlock the full potential of your portfolio" }), _jsx("p", { className: s.subTitle, children: "Access our safeguarded lending and borrowing solutions for digital assets." }), _jsx("ul", { className: s.list, children: marketingList.map(({ title, subTitle, icon }) => (_jsx(ListItem, { title: title, subTitle: subTitle, icon: icon }, title))) }), _jsx("div", { className: s.actionButton, children: _jsx("a", { href: "mailto:clientsolutions@copper.co", style: { textDecoration: 'none' }, target: "_blank", rel: "noreferrer", children: _jsx(Button, { fullWidth: true, size: "xl", text: "Contact us", view: "primary", onClick: () => sendTrackingEvent('lending_contact_button', {
                                organization_id: organizationId
                            }) }) }) }), _jsx("div", { className: s.actionDescription, children: "For more information, connect with your Relationship Manager or contact our Financing Desk directly." })] }) }));
};
