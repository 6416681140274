import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AssetIcon, PortfolioIcon } from '@copper/components';
import { VerificationStatus } from '@copper/components/AddressBook/Crypto/VerificationStatus';
import { useInfiniteAddresses } from '@copper/components/AddressBook/hooks/useInfiniteAddresses';
import { CounterpartyLogo } from '@copper/components/Counterparty/CounterpartyNetworkCard/CounterpartyLogo';
import { ExploreNetworkMessage } from '@copper/components/Counterparty/ExploreNetworkMessage';
import { HorizontalScroller } from '@copper/components/HorizontalScroller';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectCurrentOrganization, selectNonCustodialWalletsEnabled, selectOrganizationWhiteLock, selectWalledGardenManager } from '@copper/entities/organizations/organizations-selector';
import { selectNestedPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectSearchPreferences } from '@copper/entities/revault/revault-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { isCurrencyFiat } from '@copper/helpers';
import { useFetchCounterparties } from '@copper/hooks/useFetchCounterparties';
import { useCallback, useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PortfolioName } from '../PortfolioName';
import { useFetchNonCustodianWallets } from '@copper/hooks/useFetchNonCustodianWallets';
import { ButtonLink, FormControl, FormLabel, IconGradientNetwork, IconWhitelist, Select, Tabs, Tooltip } from '@copper/ui-kit';
import { truncateStr } from '@copper/utils';
import { isExternalPortfolio } from '@copper/helpers/portfolios';
import { getPortfolioBalanceInNetwork } from '../Network/helpers';
import { ShowUnavailableSwitch } from '../ShowUnavailableSwitch';
import { emptyData, emptySearch, filterNestedPortfoliosByIds, filterNestedUnavailable, generateTabs, getAvailableBalance } from './helpers';
import s from './TransferPortfolio.module.css';
const TransferPortfolio = ({ type, name, label, control, errorMessage, currencyId, mainCurrencyId, excludePortfolioIds, hasExternal = false, hasCounterparties = false, hasNonCustodialWallets = false, testId, view = 'tool', defaultShowUnavailable = false, whiteLockPortfolio, excludeSmartContracts = false }) => {
    const navigate = useNavigate();
    const { checkPermissions } = useCheckPermissionsContext();
    const searchPreferences = useSelector(selectSearchPreferences);
    const nestedPortfolios = useSelector(selectNestedPortfolios);
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const organizationWhiteLockEnabled = useSelector(selectOrganizationWhiteLock);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const [searchQueryString, setSearchQueryString] = useState('');
    const walledGardenManager = useSelector(selectWalledGardenManager);
    const nonCustodialWalletsEnabled = useSelector(selectNonCustodialWalletsEnabled);
    const [activeTab, setActiveTab] = useState('internal');
    const [showUnavailable, setShowUnavailable] = useState(defaultShowUnavailable);
    const { isSearching, counterparties, fetchCounterparties } = useFetchCounterparties({
        searchQueryString,
        isConnected: true,
        counterpartyType: ['standard'],
        enabled: hasCounterparties,
        operations: ['transfer']
    });
    const whiteLockEnabled = organizationWhiteLockEnabled && whiteLockPortfolio?.extra?.whiteLockEnabled !== false;
    const { isLoadingAddresses, handleSearchChange: handleAddressSearch, handleInfiniteScroll, cryptoAddresses: withdrawalAddresses, setFilters } = useInfiniteAddresses({
        enabled: hasExternal,
        isArchived: false
    });
    const { isLoadingNonCustodial, nonCustodialWallets, handleNonCustodialInfiniteScroll } = useFetchNonCustodianWallets({
        enabled: activeTab === 'nonCustodial' && nonCustodialWalletsEnabled,
        portfolioId: whiteLockPortfolio?.portfolioId,
        currency: currencyId,
        mainCurrency: mainCurrencyId,
        search: searchQueryString
    });
    useEffect(() => {
        setFilters({
            ...(currencyId && { currencies: [currencyId] }),
            ...(mainCurrencyId && { mainCurrencies: [mainCurrencyId] }),
            ...(whiteLockPortfolio?.portfolioId && { portfolioIds: [whiteLockPortfolio?.portfolioId] }),
            ...(whiteLockEnabled && { isWhitelist: whiteLockEnabled }),
            ...(excludeSmartContracts && {
                addressTypes: ['externally-owned-account', 'payable-smart-contract', 'internal-account']
            }),
            pendingApproval: false
        });
    }, [currencyId, mainCurrencyId, whiteLockPortfolio, whiteLockEnabled, excludeSmartContracts]);
    const hasNetworkPermissions = checkPermissions(['find-counterparties']);
    const { field: { onChange } } = useController({
        name,
        control
    });
    const value = useWatch({
        name,
        control
    });
    const handleOnChange = (value) => {
        onChange(value);
        setSearchQueryString('');
    };
    useEffect(() => {
        if (activeTab === 'external') {
            if (!hasExternal) {
                setActiveTab('internal');
            }
        }
    }, [activeTab, hasExternal]);
    useEffect(() => {
        if (activeTab === 'counterparties' && hasNetworkPermissions) {
            fetchCounterparties({
                searchQueryString,
                isConnected: true,
                counterpartyType: ['standard'],
                operations: ['transfer']
            });
        }
    }, [activeTab]);
    const getItems = useCallback(() => {
        const vaultPortfolios = filterNestedPortfoliosByIds(nestedPortfolios.vaultPortfolios, excludePortfolioIds);
        const tradePortfolios = filterNestedPortfoliosByIds(nestedPortfolios.tradePortfolios, excludePortfolioIds);
        const externalPortfolios = filterNestedPortfoliosByIds(nestedPortfolios.externalPortfolios, excludePortfolioIds);
        const feePortfolios = filterNestedPortfoliosByIds(nestedPortfolios.feePortfolios, excludePortfolioIds);
        const commonFilterNestedArguments = {
            currenciesEntities,
            walletsByPortfolioId,
            currencyId,
            mainCurrencyId,
            showDestination: type === 'destination'
        };
        const internalGroups = [
            {
                title: 'Vaults',
                nestedItems: showUnavailable
                    ? vaultPortfolios
                    : filterNestedUnavailable({
                        portfolios: vaultPortfolios,
                        ...commonFilterNestedArguments
                    })
            },
            {
                title: 'Trading',
                nestedItems: showUnavailable
                    ? tradePortfolios
                    : filterNestedUnavailable({
                        portfolios: tradePortfolios,
                        ...commonFilterNestedArguments
                    })
            },
            {
                title: 'Fee',
                nestedItems: showUnavailable
                    ? feePortfolios
                    : filterNestedUnavailable({
                        portfolios: feePortfolios,
                        ...commonFilterNestedArguments
                    })
            }
        ];
        const exchangeGroups = [
            {
                title: 'Exchange',
                nestedItems: showUnavailable
                    ? externalPortfolios
                    : filterNestedUnavailable({
                        portfolios: externalPortfolios,
                        ...commonFilterNestedArguments
                    })
            }
        ];
        if (activeTab === 'internal') {
            return internalGroups.filter(Boolean);
        }
        else if (activeTab === 'exchange') {
            return exchangeGroups;
        }
        else if (activeTab === 'external') {
            if (isCurrencyFiat(currenciesEntities, currencyId)) {
                return [
                    {
                        title: 'Bank account',
                        items: currentOrganization?.bankAccounts
                    }
                ];
            }
            return [
                {
                    title: 'Address book',
                    items: withdrawalAddresses
                }
            ];
        }
        else if (activeTab === 'nonCustodial') {
            return [
                {
                    title: '',
                    items: nonCustodialWallets
                }
            ];
        }
        else if (activeTab === 'counterparties') {
            return [
                {
                    title: '',
                    items: counterparties
                }
            ];
        }
    }, [
        activeTab,
        excludePortfolioIds,
        currenciesEntities,
        currencyId,
        nestedPortfolios.tradePortfolios,
        nestedPortfolios.vaultPortfolios,
        nestedPortfolios.externalPortfolios,
        mainCurrencyId,
        type,
        walletsByPortfolioId,
        withdrawalAddresses,
        whiteLockEnabled,
        showUnavailable,
        counterparties,
        nonCustodialWallets,
        isSearching
    ]);
    const showAddAddress = walledGardenManager === 'client' && checkPermissions(['create-crypto-address']);
    const getLoadingState = () => {
        if (activeTab === 'counterparties' && isSearching) {
            return true;
        }
        if (activeTab === 'external' && isLoadingAddresses && withdrawalAddresses.length === 0) {
            return true;
        }
        if (activeTab === 'nonCustodial' && isLoadingNonCustodial && nonCustodialWallets.length === 0) {
            return true;
        }
        return false;
    };
    const handleSearchChange = (value) => {
        if (activeTab === 'counterparties') {
            setSearchQueryString(value);
        }
        if (activeTab === 'external') {
            handleAddressSearch(value);
        }
    };
    return (_jsxs(FormControl, { errorMessage: errorMessage, children: [_jsx(FormLabel, { view: view, children: label }), _jsx(Select, { itemsGroups: getItems(), view: view, placeholder: "Select account", searchPlaceholder: "Search accounts", searchType: searchPreferences, searchFields: ['portfolioName', 'name', 'bankAccountName', 'address'], emptyDataString: activeTab === 'counterparties' ? (_jsx(ExploreNetworkMessage, {})) : (emptyData[currencyId && ['internal', 'exchange'].includes(activeTab)
                    ? 'currencyBalance'
                    : activeTab]), emptySearchString: emptySearch[activeTab], getSectionAction: (title) => {
                    if (title === 'Address book' && showAddAddress) {
                        return (_jsx(ButtonLink, { text: "Add new address", onClick: () => navigate(`/settings/address-book/crypto/addresses?createAddress=true`) }));
                    }
                }, getKey: (item) => {
                    if ('cryptoAddressId' in item) {
                        return item.cryptoAddressId;
                    }
                    if ('counterpartyId' in item) {
                        return item.counterpartyId;
                    }
                    if ('bankAccountId' in item) {
                        return item.bankAccountId;
                    }
                    if ('nonCustodialWalletId' in item) {
                        return item.nonCustodialWalletId;
                    }
                    return item.portfolioId;
                }, getIcon: (item) => {
                    if ('cryptoAddressId' in item) {
                        return _jsx(AssetIcon, { size: "s", currency: item.currency });
                    }
                    if ('nonCustodialWalletId' in item) {
                        return _jsx(AssetIcon, { size: "s", currency: item.mainCurrency });
                    }
                    if ('counterpartyId' in item) {
                        return (_jsx(CounterpartyLogo, { size: "2xs", counterpartyName: item.counterpartyName, imageId: item.imageId }));
                    }
                    if ('bankAccountId' in item) {
                        return _jsx(_Fragment, {});
                    }
                    return _jsx(PortfolioIcon, { portfolio: item });
                }, getTopRow: (item) => {
                    if ('cryptoAddressId' in item) {
                        return (_jsxs("div", { className: s.addressContainer, children: [_jsx("div", { className: s.addressName, children: _jsx(Tooltip, { label: item.name, placement: "top", type: "hint", view: "tool", children: truncateStr(item.name, 10, null, 20) }) }), item.isWhitelist && _jsx(IconWhitelist, {}), 'cryptoAddressId' in item && (_jsx(VerificationStatus, { size: "s", view: "outline", address: item }))] }));
                    }
                    if ('nonCustodialWalletId' in item) {
                        return item.name;
                    }
                    if ('counterpartyName' in item) {
                        return item.counterpartyName;
                    }
                    if ('portfolioId' in item) {
                        return _jsx(PortfolioName, { portfolio: item });
                    }
                }, getBottomRow: (item) => {
                    if ('cryptoAddressId' in item || 'nonCustodialWalletId' in item) {
                        return (_jsx(Tooltip, { label: item.address, placement: "top", type: "hint", view: "tool", children: truncateStr(item.address, 10, null, 20) }));
                    }
                    if ('counterpartyId' in item) {
                        if (item.networkTag) {
                            return _jsxs("div", { className: s.bottomRow, children: ["@", item.networkTag] });
                        }
                        return '';
                    }
                    if ('bankAccountName' in item) {
                        return item.bankAccountName;
                    }
                    if ('portfolioId' in item) {
                        if (item.isShadow) {
                            return '*****';
                        }
                        if (currencyId &&
                            mainCurrencyId &&
                            !isCurrencyFiat(currenciesEntities, currencyId) &&
                            !isExternalPortfolio(item.portfolioType)) {
                            return getPortfolioBalanceInNetwork(currencyId, mainCurrencyId, walletsByPortfolioId[item.portfolioId] ?? [], tokens);
                        }
                        return getAvailableBalance({
                            walletsByPortfolioId,
                            currenciesEntities,
                            tokens,
                            portfolio: item,
                            currencyId,
                            mainCurrencyId,
                            showTransferableAmount: true
                        });
                    }
                }, reversedStyles: (item) => 'cryptoAddressId' in item || 'counterpartyId' in item, onChange: handleOnChange, paddingLeft: "l", selected: 
                // additionalTransfer has empty object
                value && Object.keys(value).length ? value : undefined, initialOpenNodes: value?.parentKeys, renderMidSection: () => (_jsxs(_Fragment, { children: [_jsx(ShowUnavailableSwitch, { label: "Show unavailable accounts", showUnavailable: showUnavailable, setShowUnavailable: setShowUnavailable }), _jsx(HorizontalScroller, { view: view, children: _jsx("div", { className: s.tabsContainer, children: _jsx(Tabs, { view: view, items: generateTabs({
                                        hasExternal,
                                        hasCounterparties: hasCounterparties && hasNetworkPermissions,
                                        hasNonCustodialWallets: hasNonCustodialWallets &&
                                            nonCustodialWalletsEnabled &&
                                            !isCurrencyFiat(currenciesEntities, currencyId)
                                    }), value: activeTab, onChange: (item) => setActiveTab(item.value), getItemIcon: (item) => item.value === 'counterparties' ? _jsx(IconGradientNetwork, {}) : undefined }) }) })] })), testId: testId, onSearch: handleSearchChange, isLoading: getLoadingState(), filterFunction: activeTab === 'counterparties' ? ({ items }) => items : undefined, onScroll: (e) => {
                    if (activeTab === 'external') {
                        handleInfiniteScroll(e);
                    }
                    if (activeTab === 'nonCustodial') {
                        handleNonCustodialInfiniteScroll(e);
                    }
                } })] }));
};
export { TransferPortfolio };
