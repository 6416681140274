import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { AssetIcon, InfoRow } from '@copper/components';
import { CounterpartyInfo } from '@copper/components/Counterparty/CounterpartyInfo';
import { LenderFeeShareLabel } from '@copper/components/Loans';
import { loanLabel } from '@copper/components/Loans/helpers';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Button, ButtonIcon, IconInformation, Tooltip } from '@copper/ui-kit';
import { formatCurrency, formatFiat } from '@copper/utils';
import { MATURITY_DATE_DEFINITION } from '@copper/components/Loans/constants';
import { selectNetworks } from '@copper/entities/networks/networks-selectors';
import s from './Summary.module.css';
export const Summary = ({ onSubmit }) => {
    const { getValues, formState: { isSubmitting } } = useFormContext();
    const { getDecimals } = useCurrenciesInfo();
    const currenciesRates = useSelector(selectRates);
    const networks = useSelector(selectNetworks);
    const { loanType, currency, amount, maturityDate, collateralAmountOrTargetLTV, useCollateralAmount, calculatedLTVOrAmount, collateralCurrency, counterparty, interestRateBps, marginCallPercent, defaultPercent, rebalancePercent, mainCurrency, collateralMainCurrency, lenderFeeShare, useLenderFeeSharePercentage } = getValues();
    const amountMarketValue = BigNumber(amount)
        .times(currenciesRates[currency?.currency ?? ''] ?? 0)
        .toFixed();
    const collateralAmount = useCollateralAmount
        ? collateralAmountOrTargetLTV
        : calculatedLTVOrAmount;
    const targetLtv = useCollateralAmount ? calculatedLTVOrAmount : collateralAmountOrTargetLTV;
    const collateralAmountMarketValue = collateralAmount &&
        BigNumber(collateralAmount)
            .times(currenciesRates[collateralCurrency?.currency ?? ''] ?? 0)
            .toFixed();
    const isFirstLienView = loanType === 'agency-lending-first-lien-child-loan';
    const { leverageRatioThreshold } = counterparty?._embedded?.firstLienSettings || {};
    return (_jsxs("div", { className: s.mainWrapper, children: [_jsxs("div", { className: s.summaryContent, children: [_jsx("h2", { className: s.summaryTitle, children: "Summary" }), _jsx(InfoRow, { title: "Loan type", titleColor: "ghost", children: _jsx("div", { className: s.loanType, children: loanLabel(loanType) || '-' }) }), _jsx(InfoRow, { title: "Borrower", titleColor: "ghost", children: _jsx(CounterpartyInfo, { counterparty: counterparty, reverseView: true, size: "2xs" }) }), isFirstLienView && (_jsx(InfoRow, { title: "Leverage ratio threshold", titleColor: "ghost", children: leverageRatioThreshold ? `${leverageRatioThreshold}%` : 'N/A' })), _jsx(InfoRow, { title: "Loan amount", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsxs("div", { className: s.assetWrapper, children: [_jsxs("div", { className: s.asset, children: [formatCurrency(amount || '0', {
                                                    decimalPlaces: getDecimals(currency.currency, networks[mainCurrency]?.nativeCurrency, { useDictionaryDecimal: true })
                                                }), _jsx("span", { children: currency.currency })] }), _jsx("div", { className: s.assetValue, children: formatFiat(amountMarketValue) })] }), _jsx(AssetIcon, { size: "s", currency: currency.currency })] }) }), collateralAmount && collateralAmountMarketValue && (_jsxs(_Fragment, { children: [_jsx(InfoRow, { title: "Required collateral amount", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsxs("div", { className: s.assetWrapper, children: [_jsxs("div", { className: s.asset, children: [formatCurrency(collateralAmount || '0', {
                                                            decimalPlaces: getDecimals(collateralCurrency?.currency, networks[collateralMainCurrency ?? '']?.nativeCurrency, {
                                                                useDictionaryDecimal: true
                                                            })
                                                        }), _jsx("span", { children: collateralCurrency?.currency })] }), _jsx("div", { className: s.assetValue, children: formatFiat(collateralAmountMarketValue) })] }), _jsx(AssetIcon, { size: "s", currency: collateralCurrency?.currency })] }) }), _jsxs(InfoRow, { title: "LTV", titleColor: "ghost", children: [targetLtv, "%"] }), _jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsx("div", { className: s.asset, children: collateralCurrency?.currency }), _jsx(AssetIcon, { size: "s", currency: collateralCurrency?.currency })] }) })] })), _jsx(InfoRow, { titleColor: "ghost", title: _jsxs("div", { className: s.labelContainer, children: ["Maturity date", maturityDate && (_jsx(Tooltip, { view: "tool", label: MATURITY_DATE_DEFINITION, placement: "top", children: _jsx(ButtonIcon, { children: _jsx(IconInformation, { width: 16 }) }) }))] }), children: maturityDate ? format(maturityDate, `dd MMM yyyy`) : 'Open term' }), interestRateBps && (_jsxs(InfoRow, { title: "Interest rate", titleColor: "ghost", children: [interestRateBps, " BPS"] })), lenderFeeShare && (_jsxs(InfoRow, { title: _jsx(LenderFeeShareLabel, {}), titleColor: "ghost", children: [lenderFeeShare, " ", useLenderFeeSharePercentage ? '%' : 'BPS'] })), rebalancePercent && (_jsxs(InfoRow, { title: "Rebalance", titleColor: "ghost", children: [rebalancePercent, "%"] })), marginCallPercent && (_jsxs(InfoRow, { title: "Margin call", titleColor: "ghost", children: [marginCallPercent, "%"] })), defaultPercent && (_jsxs(InfoRow, { title: "Default", titleColor: "ghost", children: [defaultPercent, "%"] }))] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Confirm", onClick: onSubmit, loading: isSubmitting, testId: "new-loan-submit-button" }) })] }));
};
