import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CancelOrder } from '@copper/components/OrderPopup/ui/CancelOrder';
import { selectCurrenciesEntities } from '@copper/entities/currencies/currencies-selector';
import { selectLastToSign } from '@copper/entities/organizations/organizations-selector';
import { isCurrencyFiat } from '@copper/helpers';
import { isVaultPortfolio } from '@copper/helpers/portfolios';
import { Button } from '@copper/ui-kit';
import s from './SignInfo.module.css';
const ResultSignInfo = ({ currencyId, transaction, origin }) => {
    const navigate = useNavigate();
    const lastSigner = useSelector(selectLastToSign);
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    if (transaction.status === 'executed' ||
        (origin.portfolioType === 'external' && transaction.status !== 'co-sign-require')) {
        return null;
    }
    let title, description, hasButton;
    const coSignRequire = transaction.status === 'co-sign-require';
    const toBankAccount = transaction.extra?.toBankAccountId;
    const isCancelable = transaction._embedded?.isCancelable;
    if (toBankAccount && !coSignRequire) {
        title = 'Next steps';
        description = `We've notified Copper Admin of your transaction request. Once approved, the funds will be transferred ${origin.portfolioType !== 'oxygen-vault' ? 'to your chosen address ' : ''}immediately.`;
    }
    else if (coSignRequire) {
        if (transaction.organizationType === 'oxygen') {
            title = 'Next steps';
            description =
                'We’ve notified other approvers in your company. Once the request is fully approved it will go to the Copper Middle Office to be processed.';
        }
        else {
            title = 'Next steps';
            description = `We've notified approvers of your transaction request. If approved, the transaction will then need to be signed by a member of your team.`;
        }
    }
    else if (isVaultPortfolio(origin.portfolioType) &&
        lastSigner === 'admin' &&
        !isCurrencyFiat(currenciesEntities, currencyId)) {
        title = 'Sign transaction';
        description =
            'You need to sign one transaction at a time. Once this transaction has been broadcast, you can move on to the next one.';
        hasButton = true;
    }
    const handleButtonClick = () => {
        navigate({
            pathname: '/accounts/all',
            search: `orderId=${transaction.orderId}`
        });
    };
    if (!title && !description && !hasButton && !isCancelable)
        return null;
    return (_jsxs("div", { className: s.container, children: [title && _jsx("h3", { className: s.title, children: title }), description && _jsx("p", { className: s.description, children: description }), hasButton && _jsx(Button, { size: "l", text: "Sign", fullWidth: true, onClick: handleButtonClick }), _jsx(CancelOrder, { order: transaction })] }));
};
export { ResultSignInfo };
