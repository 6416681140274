import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { ProfileMenu } from '@copper/components/ProfileMenu';
import { ProfilePhoto } from '@copper/components/ProfilePhoto';
import { useLayoutState } from '@copper/contexts/layout';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { ButtonIcon, ButtonLink, IconArrowBack, IconClose, IconZendesk } from '@copper/ui-kit';
import { FixedOverlay } from '@copper/ui-kit/Popup/overlay';
import { setCssProperty } from '@copper/utils/styles';
import { CustodyUpdatePopup } from '../CustodyUpdatePopup';
import { SkeletonLoader } from './SkeletonLoader';
import { ToolbarContext } from './ToolbarContext';
import { ZendeskMenu } from './ZendeskMenu';
import s from './Toolbar.module.css';
export const Toolbar = ({ children, menu, profileMenu, zenDeskDisabled }) => {
    const { loadingLayout } = useLayoutState();
    const { route, setRouteContext } = useRoutingContext();
    const currentOrganization = useSelector(selectCurrentOrganization);
    const [topPanel, setTopPanel] = useState();
    const [activeTool, setActiveTool] = useState();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showZendeskMenu, setShowZendeskMenu] = useState(false);
    const onProfileClose = () => setShowProfileMenu(false);
    const onClose = () => {
        setActiveTool(undefined);
        setRouteContext(undefined);
    };
    const onZendeskMenuToggleHandler = () => setShowZendeskMenu(!showZendeskMenu);
    const showNewApps = currentOrganization?.extra?.newCuEnabled;
    const { title = '', subTitle = '', showGuideText, showGuide, onBack, onCloseClickOverride } = topPanel || {};
    useEffect(() => {
        if (activeTool) {
            setCssProperty('--modal-offset', '540px');
        }
        else {
            setCssProperty('--modal-offset', '0px');
        }
    }, [activeTool]);
    useEffect(() => {
        if (route?.name) {
            if (route.name === 'closed') {
                onClose();
            }
            else {
                setActiveTool(route?.name);
            }
        }
    }, [route]);
    useEffect(() => {
        if (loadingLayout) {
            setActiveTool(undefined);
        }
    }, [loadingLayout]);
    return (_jsxs(ToolbarContext.Provider, { value: {
            topPanel,
            setTopPanel,
            activeTool,
            setActiveTool,
            setShowProfileMenu,
            showProfileMenu
        }, children: [showProfileMenu && _jsx(FixedOverlay, { onClose: onProfileClose, superior: true }), showZendeskMenu && _jsx(FixedOverlay, { onClose: onZendeskMenuToggleHandler, superior: true }), _jsxs("div", { className: cn(s.container, {
                    [s.isOpen]: activeTool
                }), children: [!currentOrganization?.extra?.oxygen && (_jsxs(_Fragment, { children: [showNewApps && _jsx(CustodyUpdatePopup, { isNewApp: true }), _jsx(CustodyUpdatePopup, {})] })), showProfileMenu && _jsx(ProfileMenu, { onClose: onProfileClose, children: profileMenu }), showZendeskMenu && _jsx(ZendeskMenu, { onClose: onZendeskMenuToggleHandler }), _jsx("div", { className: s.wrapper, children: !loadingLayout ? (_jsxs(_Fragment, { children: [_jsx("button", { type: "button", className: s.avatar, onClick: () => setShowProfileMenu(!showProfileMenu), children: _jsx(ProfilePhoto, {}) }), menu, Boolean(!zenDeskDisabled && currentOrganization?.extra?.zendeskOrganizationId) && (_jsx(ButtonIcon, { view: "ghost", className: s.zendeskIcon, onClick: onZendeskMenuToggleHandler, children: _jsx(IconZendesk, {}) }))] })) : (_jsx(SkeletonLoader, {})) }), _jsxs("div", { className: s.panel, children: [_jsxs("div", { className: s.top, children: [_jsxs("div", { className: s.topLeft, children: [onBack && (_jsx(ButtonIcon, { className: s.backButton, onClick: () => onBack(), children: _jsx(IconArrowBack, {}) })), _jsxs("div", { children: [_jsx("h5", { className: s.title, children: title }), _jsx("div", { className: s.subTitle, children: subTitle })] })] }), _jsxs("div", { className: s.topRight, children: [showGuide && showGuideText && (_jsx(ButtonLink, { className: s.guideLink, text: showGuideText, onClick: () => showGuide() })), _jsx("div", { className: s.closeButton, children: _jsx(ButtonIcon, { onClick: () => (onCloseClickOverride ? onCloseClickOverride() : onClose()), tooltipLabel: "Close", children: _jsx(IconClose, {}) }) })] })] }), _jsx("div", { className: s.content, children: children })] })] })] }));
};
