import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import s from '../Assets.module.css';
export const KSM = {
    title: 'KSM',
    id: 'ksm',
    qa: [
        {
            question: 'How do I stake KSM?',
            answer: (_jsxs(_Fragment, { children: [_jsx("i", { children: "Step 1 - Bonding assets" }), _jsx("p", { className: s.spaceAfter, children: "First, you\u2019ll need to bond assets to the Kusama network." }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar." }), _jsx("li", { children: "Select Kusama (KSM) and then click \u201CDirect staking\u201D." }), _jsx("li", { children: "Select an account to stake from." }), _jsx("li", { children: "Choose a stake amount. Note: For nominated staking, stakers should consider the following costs in addition to the minimum required to earn rewards:" }), "- A minimum deposit of 0.666933 KSM, which is frozen and does not earn rewards. This deposit amount will be returned when you unstake and claim assets.", _jsx("br", {}), "- A service fee of 0.001099 KSM", _jsx("li", { children: "Review and sign using Copper Unlimited." })] }), _jsx("p", { className: s.spaceBefore, children: _jsx("i", { children: "Step 2 - Choosing validators" }) }), _jsx("p", { className: s.spaceAfter, children: "Next, choose one or more validators to delegate your stake to. You can do this through partner nomination or manually." }), _jsx("p", { children: "Nominated staking" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "Return to the dashboard and select Stake in the side toolbar." }), _jsx("li", { children: "Select Kusama (KSM) and then click \u201CNominated staking\u201D." }), _jsx("li", { children: "Choose the same account you previously bonded from." }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }), _jsx("p", { className: s.spaceBefore, children: "Direct staking" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On your dashboard, go to the Outstanding tab and select Add validator." }), _jsx("li", { children: "Enter the validators' addresses." }), _jsx("li", { children: "Sign using Copper Unlimited." })] })] }))
        },
        {
            question: 'How do I unstake KSM?',
            answer: (_jsx(_Fragment, { children: _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Unstake." }), _jsx("li", { children: "Sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the 7-day unbonding period." }), _jsx("li", { children: "Withdraw some or all of your unstaked KSM, and sign the transaction." }), _jsx("li", { children: "Claim the available amount and sign one last time to confirm." })] }) }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for KSM?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Yes, the minimum staking amount is 0.1 KSM, but there is no maximum amount. To confirm the minimum stake to earn rewards, we recommend you visit", ' ', _jsx("a", { href: "https://staking.polkadot.cloud/#/nominate", target: "_blank", rel: "noreferrer", children: "Polkadot\u2019s website." })] }), _jsx("p", { children: "Note: For nominated staking, stakers should consider the following costs in addition to the minimum required to earn rewards:" }), _jsxs("ul", { className: s.ul, children: [_jsx("li", { children: "A minimum deposit of 0.666933 KSM, which is frozen and does not earn rewards. This deposit amount will be returned when you unstake and claim assets." }), _jsx("li", { children: "A service fee of 0.001099 KSM" })] }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "0.1 KSM" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake" }) }), _jsx("td", { className: s.td, children: "None" })] })] }) })] }))
        },
        {
            question: 'How often will I earn KSM staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "KSM rewards are issued daily." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Daily" })] }) }) })] }))
        },
        {
            question: 'How can I claim my KSM staking rewards?',
            answer: (_jsx("p", { children: "Rewards for KSM are automatically restaked. You will need to unstake assets to claim rewards. For more information, see \u201CHow to unstake\u201D." }))
        },
        {
            question: 'How do I switch validators?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "With direct staking, you can switch validators by following the below steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "On the dashboard, click the row showing your KSM staking details." }), _jsx("li", { children: "Under Validators, select Change and follow on-screen instructions." }), _jsx("li", { children: "Sign using Copper Unlimited." })] }), _jsx("p", { children: "This applies exclusively to direct staking. For nominated staking, nominations are managed by one of our staking partners." })] }))
        }
    ]
};
