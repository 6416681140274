import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormResultAccent, FormResultFrame, FormResultHeading, FormResultRegular } from '@copper/components/FormResultFrame';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { Alert } from '@copper/ui-kit';
import { selectUser } from '@copper/entities/user/user-selector';
import { formatCurrency } from '@copper/utils';
import s from './Result.module.css';
export const Result = ({ errorMessage, formData, onDone }) => {
    const groupedPortfolios = useSelector(selectPortfolios);
    const { amount, currency, type, account } = formData;
    const { isAdmin } = useSelector(selectUser);
    if (!currency || !account || !amount) {
        return null;
    }
    const getResultStatus = () => {
        if (errorMessage) {
            return 'failure';
        }
        return 'success';
    };
    const getHeadingText = () => {
        if (errorMessage) {
            return 'Transaction failed';
        }
        if (type === 'delegate') {
            return 'Pledge initiated - awaiting secondary client approval';
        }
        return isAdmin ? 'Admin initiated Asset UnPledge' : 'Assets unpledged';
    };
    const parentPortfolioId = account?.extra?.parentPortfolioId;
    if (!parentPortfolioId) {
        return null;
    }
    const parentPortfolio = groupedPortfolios[parentPortfolioId];
    if (!parentPortfolio) {
        return null;
    }
    const getResultRoute = () => {
        const originAccount = type === 'delegate' ? parentPortfolio : account;
        const destinationAccount = type === 'delegate' ? account : parentPortfolio;
        if (errorMessage) {
            return (_jsxs(_Fragment, { children: ["From", ' ', _jsx(NavLink, { to: `/accounts/${originAccount?.portfolioId}`, children: originAccount?.portfolioName }), ' ', "to", ' ', _jsx(NavLink, { to: `/accounts/${destinationAccount?.portfolioId}`, children: destinationAccount?.portfolioName })] }));
        }
        if (type === 'undelegate') {
            if (isAdmin) {
                return 'Client has been notified by email of Asset UnPledge';
            }
            return (_jsxs(_Fragment, { children: ["Assets are available in your", ' ', _jsx(NavLink, { to: `/accounts/${parentPortfolio?.portfolioId}`, children: parentPortfolio?.portfolioName }), "."] }));
        }
        return 'Assets are safely pledged and available in your exchange account.';
    };
    const status = getResultStatus();
    const headingText = getHeadingText();
    return (_jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsx(FormResultHeading, { status: status, children: headingText }), _jsxs(FormResultAccent, { children: [formatCurrency(amount), " ", currency.name] }), _jsx(FormResultRegular, { children: getResultRoute() }), errorMessage && (_jsx("div", { className: s.error, children: _jsx(Alert, { type: "error", message: errorMessage }) }))] }));
};
