import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from 'react-router';
import { FormResultFrame } from '@copper/components';
import { isLoanWithCollateralType } from '@copper/components/Loans/helpers';
import { selectNetworks } from '@copper/entities/networks/networks-selectors';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Alert, ButtonLink } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import s from './Result.module.css';
export const Result = ({ onDone, onRepeat, loan, error }) => {
    const navigate = useNavigate();
    const { getValues } = useFormContext();
    const { getDecimals } = useCurrenciesInfo();
    const networks = useSelector(selectNetworks);
    const { amount, currency, mainCurrency, collateralAmountOrTargetLTV, collateralCurrency, collateralMainCurrency, loanType, useCollateralAmount, calculatedLTVOrAmount } = getValues();
    const collateralAmount = useCollateralAmount
        ? collateralAmountOrTargetLTV
        : calculatedLTVOrAmount;
    const status = error ? 'failure' : loan?.status === 'awaiting-approval' ? 'pending' : 'success';
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.frameContainer, children: _jsxs(FormResultFrame, { status: status, onDone: onDone, children: [_jsxs("p", { className: cn(s.title, s[status]), children: [status === 'failure' && 'Loan creation failed', status === 'pending' && 'Awaiting approval', status === 'success' && 'Your loan has been booked'] }), status === 'pending' && (_jsx("div", { className: s.description, children: "Your lending request has been sent for approval by an authorised team member." })), status === 'success' && (_jsxs(_Fragment, { children: [_jsxs("p", { className: s.loanAmount, children: [formatCurrency(amount || '0', {
                                            decimalPlaces: getDecimals(currency.currency, networks[mainCurrency ?? '']?.nativeCurrency, { useDictionaryDecimal: true })
                                        }), ' ', currency.currency] }), isLoanWithCollateralType(loanType) && (_jsxs("p", { className: s.collateralAmount, children: ["Collateral", ' ', formatCurrency(collateralAmount || '0', {
                                            decimalPlaces: getDecimals(collateralCurrency?.currency, networks[collateralMainCurrency ?? '']?.nativeCurrency, {
                                                useDictionaryDecimal: true
                                            })
                                        }), ' ', collateralCurrency?.currency] }))] })), status === 'failure' && (_jsx("div", { className: s.errorWrapper, children: _jsx(Alert, { type: "error", message: error, noBottomMargin: true }) }))] }) }), !error && loan && (_jsx("div", { className: s.item, children: _jsx("div", { className: s.button, children: _jsx(ButtonLink, { text: "View loan details", onClick: () => navigate(`/accounts/all/bilateral-lending?loanId=${loan?.loanId}`) }) }) })), _jsx("div", { className: s.item, children: _jsx("div", { className: s.button, children: _jsx(ButtonLink, { text: "Repeat loan", onClick: onRepeat }) }) })] }));
};
