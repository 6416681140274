import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import { fromZonedTime } from 'date-fns-tz';
import useStateMachine from '@cassiozen/usestatemachine';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectStakingCurrencies, selectValidatorProviders } from '@copper/entities/stake/stake-selector';
import { canStakeDelegation, canStakeNomination, hasEndTime, hasPendingPoolCalculation } from '@copper/helpers/stake';
import { useFetchStakePool } from '@copper/hooks/useFetchStakePools';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { StakeForm } from './Form';
import { StakingGuide } from './Guide';
import { Initial } from './Initial';
import { Methods } from './Methods';
import { Result } from './Result';
import { Summary } from './Summary';
import { getBlockchainTransactionType, isNewStakingStructure, validationSchema } from './helpers';
export const Stake = () => {
    const dispatch = useDispatch();
    const { setTopPanel } = useToolbar();
    const { route } = useRoutingContext();
    const portfolios = useSelector(selectPortfolios);
    const stakingCurrencies = useSelector(selectStakingCurrencies);
    const validatorProviders = useSelector(selectValidatorProviders);
    const [transaction, setTransaction] = useState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });
    const { getValues, reset, setValue, watch } = form;
    const { currency, method, portfolio, poolOperation } = watch();
    const currencyMayHavePool = (canStakeDelegation(currency) && !canStakeNomination(currency)) || currency?.currency === 'SOL';
    const { predefinedPool, isFetchingPool } = useFetchStakePool(currency?.currency, currency?.mainCurrency, portfolio?.portfolioId, currencyMayHavePool, hasPendingPoolCalculation(currency));
    const [state, send] = useStateMachine({
        initial: 'initial',
        states: {
            initial: {
                on: {
                    NEXT_STEP: 'form',
                    RESET_FORM: 'form',
                    SHOW_GUIDE: 'guide',
                    SHOW_METHODS: 'methods'
                }
            },
            methods: {
                on: {
                    BACK_STEP: 'initial',
                    NEXT_STEP: 'form'
                }
            },
            form: {
                on: {
                    BACK_STEP: 'initial',
                    NEXT_STEP: 'summary',
                    SHOW_GUIDE: 'guide',
                    SHOW_METHODS: 'methods'
                }
            },
            summary: {
                on: {
                    BACK_STEP: 'form',
                    NEXT_STEP: 'result',
                    RESET_FORM: 'form',
                    SHOW_GUIDE: 'guide'
                }
            },
            result: {
                on: {
                    RESET: 'initial',
                    SHOW_GUIDE: 'guide'
                }
            },
            guide: {
                on: {
                    BACK_STEP: 'initial'
                }
            }
        }
    });
    useEffect(() => {
        if (isNewStakingStructure(currency, validatorProviders)) {
            setValue('poolOperation', 'selectValidator');
            return;
        }
        if (currencyMayHavePool) {
            if (currency?.currency === 'ETH' && method !== 'liquid') {
                setValue('poolOperation', 'figment');
            }
            else if (currency?.currency === 'ETH' || predefinedPool) {
                setValue('poolOperation', 'selectPredefined');
            }
            else {
                if (currency?.currency === 'TAO') {
                    setValue('poolOperation', 'p2p');
                }
                else {
                    setValue('poolOperation', 'connect');
                }
            }
        }
    }, [currency, method, predefinedPool, setValue]);
    useEffect(() => {
        setValue('predefinedPool', predefinedPool);
    }, [predefinedPool, setValue]);
    useEffect(() => {
        setTopPanel({
            title: state.value === 'guide' ? 'Staking guide' : 'Stake',
            showGuideText: state.value === 'initial' ? 'Staking guide' : undefined,
            onBack: ['form', 'methods', 'summary'].includes(state.value)
                ? () => send('BACK_STEP')
                : undefined,
            showGuide: state.value === 'initial' ? () => send('SHOW_GUIDE') : undefined,
            onCloseClickOverride: state.value === 'guide' ? () => send('BACK_STEP') : undefined
        });
    }, [state, send, setTopPanel]);
    useEffect(() => {
        if (state.value === 'initial') {
            reset();
        }
    }, [state.value]);
    useEffect(() => {
        if (route?.name === 'stake') {
            const currencyId = route?.data?.currencyId === 'STETH' ? 'ETH' : route?.data?.currencyId;
            currencyId && send('RESET_FORM');
            currencyId === 'ETH' && send('SHOW_METHODS');
            const defaultValues = {
                currency: stakingCurrencies.find(({ currency }) => currency === currencyId),
                portfolio: portfolios[route?.data?.portfolioId ?? '']
            };
            reset(defaultValues);
        }
    }, [route]);
    const handleAssetClick = (currency) => {
        setValue('currency', currency);
        if (currency.currency === 'ETH') {
            send('SHOW_METHODS');
        }
        else {
            send('NEXT_STEP');
        }
    };
    const handleMethodClick = (method) => {
        setValue('method', method);
        send('NEXT_STEP');
    };
    const handleFormSubmit = () => {
        send('NEXT_STEP');
    };
    const handleFormSend = async () => {
        const { portfolio, currency, amount, poolId, remoteAccountPublicKey, predefinedPool, period, rewardAddress, endTime, rewardCycle, signerSignature, signerKey, authId, method, drep, validatorProvider } = getValues();
        if (!portfolio || !currency) {
            return;
        }
        const data = {
            externalOrderId: cuid(),
            orderType: 'withdraw',
            portfolioId: portfolio.portfolioId,
            baseCurrency: currency.currency,
            mainCurrency: currency.mainCurrency,
            amount: amount ?? '0',
            poolId: poolOperation === 'selectPredefined' ? predefinedPool?.poolId : poolId?.trim(),
            blockchainTransactionType: getBlockchainTransactionType(currency, method, poolOperation),
            remoteAccountPublicKey: remoteAccountPublicKey?.trim(), // XEM
            period, // STX
            drep, // ADA,
            ...(currency.currency === 'TAO' && {
                netuid: '0', // Temporary pass 0 for TAO until we will support more subnets,
                poolId: currency.extra?.stakingProviders?.find(({ name }) => name?.toLowerCase() === poolOperation)?.contractAddress
            }),
            rewardAddress: rewardAddress?.trim(), // STX, BTC
            ...(hasEndTime(currency) &&
                endTime && {
                endTime: Math.floor(fromZonedTime(endTime, 'UTC').getTime() / 1000).toString()
            }),
            ...(currency.currency === 'STX' && {
                rewardCycle,
                signerSignature,
                signerKey,
                authId
            }),
            ...(currency.currency === 'ETH' &&
                method === 'liquid' && {
                quoteCurrency: 'STETH'
            }),
            ...(currency.currency === 'ETH' &&
                poolOperation === 'figment' && {
                toAddress: currency.extra?.stakingProviders?.find(({ name }) => name === 'Figment')
                    ?.contractAddress
            }),
            ...(poolOperation === 'selectValidator' && {
                validatorProviderId: validatorProvider?.id
            })
        };
        setIsSubmitting(true);
        setTransactionErrorMessage(undefined);
        try {
            const createdOrder = await dispatch(createOrder(data)).unwrap();
            setTransaction(createdOrder);
        }
        catch (err) {
            setTransactionErrorMessage(getErrorData(err).message);
        }
        finally {
            setIsSubmitting(false);
            send('NEXT_STEP');
        }
    };
    const handleDone = () => {
        reset();
        send('RESET');
    };
    return (_jsxs(_Fragment, { children: [state.value === 'initial' && _jsx(Initial, { onNext: handleAssetClick }), state.value === 'methods' && _jsx(Methods, { currency: currency, onNext: handleMethodClick }), state.value === 'form' && (_jsx(StakeForm, { form: form, isFetchingPool: isFetchingPool, onSubmit: handleFormSubmit })), state.value === 'summary' && (_jsx(Summary, { data: { ...getValues() }, isSubmitting: isSubmitting, onNext: handleFormSend })), state.value === 'result' && (_jsx(Result, { errorMessage: transactionErrorMessage, formData: { ...getValues() }, transaction: transaction, onDone: handleDone })), state.value === 'guide' && _jsx(StakingGuide, {})] }));
};
