import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { AssetIcon } from '@copper/components';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName, getCurrencyProtocol } from '@copper/helpers';
import { Atom } from '@copper/ui-kit';
export const NetworkInfo = ({ currency, mainCurrency }) => {
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    if (!mainCurrency) {
        return;
    }
    const currencyProtocol = getCurrencyProtocol(currency ?? '', mainCurrency ?? '', tokens);
    return (_jsx(Atom, { align: "right", title: mainCurrency, subtext: _jsxs(_Fragment, { children: [getCurrencyName(mainCurrency ?? '', currenciesEntities), currencyProtocol && ` (${currencyProtocol?.toUpperCase()})`] }), icon: _jsx(AssetIcon, { size: "s", currency: mainCurrency }) }));
};
