import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ChoiceGroup, FormControl, FormLabel, Loader, useSnackBar } from '@copper/ui-kit';
import { InfoRow } from '@copper/components';
import { FundsAmount } from '@copper/components/Forms/FundsAmount';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { NetworkInfo } from '@copper/components/NetworkInfo';
import { selectActivePlatformCurrencies, selectSecurityCurrencies } from '@copper/entities/currencies/currencies-selector';
import { getMarketPrice } from '@copper/entities/dictionaries/dictionaries-actions';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { formatCurrency, getErrorData } from '@copper/utils';
import { mainCurrency, mmfStableCoin } from '../helpers';
import s from './OxygenInvest.module.css';
export const OxygenInvestForm = ({ onNext }) => {
    const { openSnackbar } = useSnackBar();
    const { getDecimals } = useCurrenciesInfo();
    const securityCurrencies = useSelector(selectSecurityCurrencies);
    const currencies = useSelector(selectActivePlatformCurrencies);
    const [awaitQuote, setAwaitQuote] = useState(false);
    const mmfStableCurrencies = currencies.filter((currency) => currency.currency === mmfStableCoin);
    const { control, formState: { errors }, setValue, watch, reset } = useFormContext();
    const { baseCurrency, quoteCurrency, portfolio, baseAmount, quoteAmount, marketPrice, investType } = watch();
    const fetchMarketPrice = async () => {
        if (!baseCurrency || !quoteCurrency) {
            return;
        }
        setAwaitQuote(true);
        try {
            const marketPrice = await getMarketPrice(`${baseCurrency?.currency}-${quoteCurrency.currency}`);
            setValue('marketPrice', marketPrice);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
        finally {
            setAwaitQuote(false);
        }
    };
    useEffect(() => {
        if (baseCurrency && quoteCurrency) {
            fetchMarketPrice();
        }
    }, [baseCurrency, quoteCurrency]);
    useEffect(() => {
        reset({
            investType,
            portfolio
        });
    }, [investType]);
    useEffect(() => {
        if (!baseAmount && marketPrice && quoteAmount) {
            const baseAmount = new BigNumber(quoteAmount)
                .dividedBy(marketPrice.price)
                .toFixed(getDecimals(quoteCurrency?.currency));
            setValue('baseAmount', baseAmount);
        }
    }, [quoteCurrency, baseAmount, marketPrice, quoteAmount]);
    const handleBaseAmountChange = (value, sourceInfo) => {
        if (sourceInfo.source === 'prop') {
            return;
        }
        if (marketPrice && quoteCurrency) {
            const quoteAmount = new BigNumber(value)
                .times(marketPrice.price)
                .toFixed(getDecimals(quoteCurrency.currency));
            setValue('quoteAmount', quoteAmount);
        }
    };
    const handleQuoteAmountChange = (value, sourceInfo) => {
        if (sourceInfo.source === 'prop') {
            return;
        }
        if (marketPrice && baseCurrency) {
            const baseAmount = new BigNumber(value)
                .dividedBy(marketPrice.price)
                .toFixed(getDecimals(baseCurrency.currency));
            setValue('baseAmount', baseAmount);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("form", { id: "invest-form", className: s.formContainer, onSubmit: onNext, children: [_jsx(FormControl, { children: _jsx(Controller, { name: "investType", control: control, render: ({ field }) => (_jsx(ChoiceGroup, { size: "s", view: "tool", items: [
                                    { label: 'Subscribe', value: 'subscribe' },
                                    { label: 'Redeem', value: 'redeem' }
                                ], name: field.name, value: field.value, getItemLabel: (item) => item.label, getItemKey: (item) => item.value, onChange: ({ newValue }) => field.onChange(newValue.value) })) }) }), _jsx(PortfolioSelect, { name: "portfolio", label: "Account", control: control, currencyId: investType === 'subscribe' ? quoteCurrency?.currency : baseCurrency?.currency, types: ['custody'], errorMessage: errors?.portfolio?.message, hasUnavailableToggle: false }), _jsxs(FormControl, { errorMessage: errors?.quoteAmount?.message || errors.quoteCurrency?.message, children: [_jsx(FormLabel, { view: "tool", children: investType === 'subscribe' ? 'Invest' : 'Receive' }), _jsx(FundsAmount, { view: "tool", inputName: "quoteAmount", selectName: "quoteCurrency", control: control, currencyItems: mmfStableCurrencies, portfolioId: portfolio?.portfolioId, onInputValueChange: handleQuoteAmountChange })] }), _jsxs(FormControl, { errorMessage: errors?.baseAmount?.message || errors.baseCurrency?.message, children: [_jsx(FormLabel, { view: "tool", children: investType === 'subscribe' ? 'Receive' : 'Redeem' }), _jsx(FundsAmount, { view: "tool", inputName: "baseAmount", selectName: "baseCurrency", control: control, currencyItems: securityCurrencies, portfolioId: portfolio?.portfolioId, onInputValueChange: handleBaseAmountChange })] }), _jsx(InfoRow, { title: "Price", titleColor: "ghost", children: awaitQuote ? (_jsx(Loader, { view: "tool", size: "s" })) : marketPrice ? (`1 ${baseCurrency?.currency ?? ''} per ${formatCurrency(marketPrice.price, {
                            decimalPlaces: getDecimals(baseCurrency?.currency ?? ''),
                            zeroFormat: '0.0000'
                        })} ${quoteCurrency?.currency ?? ''}`) : ('0.0000') }), _jsx(InfoRow, { title: "Network", titleColor: "ghost", children: _jsx(NetworkInfo, { currency: mmfStableCoin, mainCurrency: mainCurrency }) })] }), _jsx(SwitchStateRow, { form: "invest-form", type: "submit", text: "Preview order" })] }));
};
